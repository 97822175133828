import {saveToken, loadToken, clearToken} from '../pages/new/sessionStorage';
import {constants} from '../config';
import {clearState} from '../pages/new/localstorage';
import Cookies from "js-cookie";

export const setAuthToken = (token) => {
    saveToken(token);
}

export const getAuthToken = () => {
    return loadToken();
}

export const clearAuthToken = () => {
    clearToken();
}

export const clearSession = ()=> {
    clearAuthToken();
    clearState();
    const domain = 'https://' + location.hostname
    // After signup user should have correct grails context about pretest
    const path = {path: '/', domain: `.${domain}`};
    Cookies.remove('pre-test-completed-time', path);
    Cookies.remove('currentStudentId', path);
    Cookies.remove('currentUserToken', path);
}
export const logout = () => {
    clearSession()
    window.location.replace('/auth/logout')
}
