import log from 'common/Logger';
import bridge from 'common/bridge';
import Dfp from 'ads/dfp/dfp';
import dfp_config from 'ads/dfp/dfp_config';
import Utils from 'common/Utils';
import _ from 'lodash';
import { generateAllBidders } from './hb/hb_bidders_config';
import config from './dfp/dfp_config';
import { isSafari } from '../common/browser_utils';
import { getCountry, isTeacher } from '../common/UserService';
import { loadGoogleConsent } from '../googleconsent';
import { ready } from '../common/Utilities';

// import {loadGeoEdge} from './geoedge'
class Ads {

  constructor(isLazy, dfpModule) {
    this.initPromise = null;
    this.isInit = false;
    this.dfp = dfpModule;
    this.currPlacementIdx = 1;
    this.isLazy = isLazy;
    if (this.isLazy) {
      let options = {
        root: null, // relative to document viewport
        rootMargin: '0px 0px 50% 0px', // margin around root. Values are similar to css property. Unitless values not allowed
        threshold: 1.0, // visible amount of item shown in relation to root
      };
      this.observer = new IntersectionObserver(this.onLazyLoad.bind(this), options);
    }
    /**
     * contains internal functions that limit sizes of placements
     * see quiz sidebar ad unit limitation function for example
     * @type {placement name -> ((Array of sizes) -> Array of sizes)}
     */
    this.sizeLimitationFuncs = {};
    this.loadAutoAds = _.once(this.loadAutoAds.bind(this))
  }

  onLazyLoad(entries, observer) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        let placementEl = entry.target;
        observer.unobserve(placementEl);
        log.debug('lazy loaded placement ' + placementEl.dataset.pmdPlacementIdx);
        this.dfp.fillPlacementsWithAds(this.pageConfig.name);
        this.dfp.displaySingleAd(placementEl, true, this.pageConfig.name);
      }
    });
  }

  async init() {
    log.debug('Ads::init() called');
    if (this.initPromise) return this.initPromise;
    this.initPromise = new Promise(async (resolve, reject) => {
      log.debug(`Ads::init`);
      if (this.isAdsDisabled() || this.isInit) resolve(true);
      this.isInit = true;
      try {
        await loadGoogleConsent();
        log.info('Ads::init -> CONSENT_DATA_READY');
      }
      catch (e) {
        log.error(`loadGoogleConsent problem - ${e.message}`, e)
      }
      finally {
        resolve(true)
      }
    });
    return this.initPromise;
    // this.lastHooksCount = -9999;
  }

  createPlacementEl(placementName, extraStyle = {}, extraData = {}) {
    log.debug('Ads::createPlacementEl() called');
    let el = document.createElement('div');
    el.classList.add('pomad-placement');
    el.dataset.pmdPlacementIdx = this.currPlacementIdx;
    el.dataset.pmdPlacementName = placementName;
    Object.assign(el.dataset, extraData);
    Object.assign(el.style, extraStyle);
    el.style.textAlign = 'center';
    el.id = 'pmd-placement-' + placementName + '-' + this.currPlacementIdx;
    this.currPlacementIdx++;
    return el;
  }

  createPlacementBefore(placementName, hookEl, extraStyle = {}, extraData = {}) {
    if (hookEl) {
      let plEl = this.createPlacementEl(placementName, extraStyle, extraData);
      hookEl.parentNode.insertBefore(plEl, hookEl);
    }
  }

  getLeftSideWidth() {
    let leftSideWidth = 0;
    const isDesktop = Utils.isDesktop();
    const studentInitialLevel = _.result(bridge, 'session.user.student.initialLevel');
    const questions = _.result(bridge, 'page_data.command.questionList', []);
    const questionCount = questions.length;
    if (this.pageConfig.name !== 'nResults') {
      if (studentInitialLevel) {
        if (isDesktop) {
          leftSideWidth = document.querySelector('.quiz-action-panel__markers').offsetWidth;
        } else {
          leftSideWidth = (questionCount > 5 ? 30 : 36) * questionCount;
        }
      } else {
        leftSideWidth = 288;
      }
    }
    return leftSideWidth;
  }

  getNextButtonWidth() {
    log.debug('Ads::getNextButtonWidth() called');
    let nextBtnWidth = 0;
    const isDesktop = Utils.isDesktop();
    if (this.pageConfig.name !== 'nResults') {
      if (isDesktop) {
        if (this.pageConfig.name === 'review') {
          nextBtnWidth = document.querySelector('.quiz-action__review-btn.next-btn').offsetWidth * 2
        }
      } else {
        nextBtnWidth = this.pageConfig.name === 'review' ? 142 : 205;
      }
    } else {
      nextBtnWidth = 209;
    }
    return nextBtnWidth;
  }

  _updateSizeLimitationsForQuizPage(width) {
    log.debug('Ads::_updateSizeLimitationsForQuizPage() called');
    const topAdsMaxWidth = width > 728 ? 728 : width;
    let footerAdsMaxWidth = topAdsMaxWidth;
    let sizeLimitationFunction = (sizes) => {
      sizes = _.filter(sizes, size => !_.isArray(size) || size[0] == 1 ? size : size[0] <= topAdsMaxWidth);
      if (sizes.length == 1 && sizes[0] == 1) return [];
      return sizes;
    };
    this.sizeLimitationFuncs['top'] = sizeLimitationFunction;
    this._updateMinMaxLayoutForTopAdUnit(width);

    // aside ads
    const asideMaxWidth = width > 1572 ? 300 : 160;
    const screenHeight = Utils.getWindowHeight();
    const asideMaxHeight = this.pageConfig.name === 'nQuiz' ? 1000 : screenHeight - 81;
    const asideSizeLimitationFunction = (sizes) => {
      sizes = _.filter(sizes, size => !_.isArray(size) || size[0] == 1 ? size : (size[0] <= asideMaxWidth && size[1] <= asideMaxHeight));
      if (sizes.length == 1 && sizes[0] == 1) return [];
      return sizes;
    };


    this.sizeLimitationFuncs['aside_left'] = asideSizeLimitationFunction;
    this.sizeLimitationFuncs['aside_right'] = asideSizeLimitationFunction;

    const asideLeftPlacementEl = document.querySelector('#pomad-placement-aside_left-2');
    const asideRightPlacementEl = document.querySelector('#pomad-placement-aside_right-5');
    if (asideLeftPlacementEl) {
      if (width > 1366) {
        if (width <= 1572) {
          asideLeftPlacementEl.style['max-width'] = '160px';
          asideRightPlacementEl.style['max-width'] = '160px';
        }
      } else {
        if (width < 1330) {
          asideLeftPlacementEl.style['display'] = 'none';
        }
        asideRightPlacementEl.style['display'] = 'none';
      }
    }

    // sidebar ads
    const sidebarMaxWidth = width > 1270 ? 600 : 336;
    const sidebarMaxHeight = width > 1270 ? 600 : 600;
    const sidebarSizeLimitationFunction = (sizes) => {
      sizes = _.filter(sizes, size => !_.isArray(size) || size[0] == 1 ? size : (size[0] <= sidebarMaxWidth && size[1] <= sidebarMaxHeight));
      if (sizes.length == 1 && sizes[0] == 1) return [];
      return sizes;
    };
    this.sizeLimitationFuncs['sidebar'] = sidebarSizeLimitationFunction;

    // footer ads
    const minWidth = this.pageConfig.name === 'review' ? 1990 : 1770;
    const el = document.querySelector('.quiz-action-panel')
    if (el) {
      footerAdsMaxWidth = 468;
      let actionPanelWidth = el.offsetWidth;
      if (actionPanelWidth >= 1366) {
        actionPanelWidth = 1366;
      }

      let leftSideWidth = 0;
      let nextBtnWidth = 0;
      let offsetPadding = 80;
      if (this.pageConfig.name !== 'nResults') {
        leftSideWidth = this.getLeftSideWidth();
        nextBtnWidth = this.getNextButtonWidth();
      } else {
        actionPanelWidth = width > 1366 ? 1366 : width;
        nextBtnWidth = this.getNextButtonWidth();
        offsetPadding = 36;
      }

      const availableFooterAdsWidth = actionPanelWidth - (leftSideWidth + nextBtnWidth) - offsetPadding;
      if (footerAdsMaxWidth > availableFooterAdsWidth) {
        footerAdsMaxWidth = availableFooterAdsWidth < 468 ? availableFooterAdsWidth : 468;
      } else {
        if (availableFooterAdsWidth >= 970) {
          footerAdsMaxWidth = 970;
        } else if (availableFooterAdsWidth >= 728) {
          footerAdsMaxWidth = 728;
        }
      }
    } else {
      if (width >= minWidth) {
        footerAdsMaxWidth = 728;
        if (width >= 1960) {
          footerAdsMaxWidth = 970;
        }
      }
    }

    sizeLimitationFunction = (sizes) => {
      sizes = _.filter(sizes, size => !_.isArray(size) || size[0] == 1 ? size : size[0] <= footerAdsMaxWidth);
      if (sizes.length == 1 && sizes[0] == 1) return [];
      return sizes;
    };
    this.sizeLimitationFuncs['footer'] = sizeLimitationFunction;
  }

  _updateMinMaxLayoutForTopAdUnit(width) {
    log.debug('Ads::_updateMinMaxLayoutForTopAdUnit() called');
    const topPlacementEl = document.querySelector('#pomad-placement-top-1')
    if (topPlacementEl && width > 770) {
      if (width < 728) {
        topPlacementEl.style['min-width'] = `${width - 40}px`;
        topPlacementEl.style['max-width'] = `${width - 40}px`;
      } else {
        topPlacementEl.style['min-width'] = '728px';
        topPlacementEl.style['max-width'] = '728px';
      }
    }
  }

  _createPlacementsForQuizPage(width) {
    log.debug('Ads::_createPlacementsForQuizPage() called');
    const mainContainerEl = document.querySelector('.mainContainer');
    const quizContainerEl = document.getElementById('presentQuiz-container');
    if (mainContainerEl && quizContainerEl) {
      mainContainerEl.style['display'] = 'flex';
      mainContainerEl.style['max-width'] = '100%';
      mainContainerEl.style['justify-content'] = 'center';

      quizContainerEl.style['display'] = 'inline-block';
      quizContainerEl.style['max-width'] = '908px';

      const leftWrapEl = document.createElement('div');
      leftWrapEl.classList.add('left-side-wrapper');

      const rightWrapEl = document.createElement('div');
      rightWrapEl.classList.add('right-side-wrapper');

      mainContainerEl.insertBefore(leftWrapEl, mainContainerEl.firstChild);
      mainContainerEl.appendChild(rightWrapEl);

      let placementEl = this.createPlacementEl('quiz-aside', {
        width: width > 1572 ? '300px' : '160px',
        'max-width': width > 1572 ? '300px' : '160px',
        // height: '600px',
        // background: 'red',
        position: isSafari ? '-webkit-sticky' : 'sticky',
        display: 'inline-block',
        top: '36px',
        left: 0,
        'margin-right': '36px',
        'margin-top': '36px',
        // position: -webkit-sticky;
      });
      leftWrapEl.appendChild(placementEl);
      const sizeLimitationFunction = (sizes) => {
        sizes = _.filter(sizes, size => !_.isArray(size) || size[0] == 1 ? size : size[0] <= Utils.getWidth(leftWrapEl));
        if (sizes.length == 1 && sizes[0] == 1) return [];
        return sizes;
      };
      this.sizeLimitationFuncs['quiz-aside'] = sizeLimitationFunction;
    }
  }

  /**
   * The function used to create dynamic placements on the pages
   * @private
   */
  _createOrUpdatePlacementsForPage() {
    log.debug('Ads::_createOrUpdatePlacementsForPage() called');
    let width = window.innerWidth;
    if (
      this.pageConfig.name === 'quiz'
      && !this.pageConfig.is_mobile
      && !_.isEmpty(this.pageConfig.placements['quiz-aside'])
      && width >= 1200) {
      this._createPlacementsForQuizPage(width);
    } else if (
      this.pageConfig.name === 'myProgress'
      && !isTeacher()) {
      this._updatePlacementsForPage(width);
    } else if (
      this.pageConfig.name === 'nQuiz' || this.pageConfig.name === 'review' || this.pageConfig.name === 'nResults'
    ) {
      this._updateSizeLimitationsForQuizPage(width);
    } else if (this.pageConfig.name === 'studentDashboard') {
      this._updatePlacementsForDashboardPage(width);
    }
  }

  _updatePlacementsForPage(width) {
    const self = this;
    self.sizeLimitationFuncs['aside'] = (sizes) => {
      sizes = _.filter(sizes,
        size => !_.isArray(size) || size[0] == 1 ? size : size[0] <= (width >= 1624 ? 300 : 160));
      if (sizes.length == 1 && sizes[0] == 1) return [];
      return sizes;
    };

    ready(function () {
      const studentContainerEl = document.querySelector('#studentProfileDataDiv');
      const reviewSummaryTemplate = document.querySelector('#reviewQuizResults-template');
      const mainContainerEl = studentContainerEl ? studentContainerEl : reviewSummaryTemplate;
      const isNewReport = location.pathname.includes('/app/student/report')
      const idPrefix = !isNewReport ? 'pmd' : 'pomad';
      const topPlacementEl = document.querySelector(`#${idPrefix}-placement-top-1`);
      const asidePlacementEl = document.querySelector(`#${idPrefix}-placement-aside-2`);
      const midTopPlacementEl = document.querySelector(`#${idPrefix}-placement-mid-top-3`);
      const footerPlacementEl = document.querySelector('#my-progress-ad');
      if (!self.pageConfig.is_mobile && mainContainerEl) {
        mainContainerEl.style['max-width'] = '1024px';
      }
      if (!Utils.isMobileByWidth()) {
        if (topPlacementEl) {
          topPlacementEl.style['max-width'] = '970px';
          topPlacementEl.style['max-height'] = '180px';
        }
        if (midTopPlacementEl) {
          midTopPlacementEl.style['max-width'] = '930px';
          midTopPlacementEl.style['max-height'] = '180px';
        }
        if (footerPlacementEl) {
          footerPlacementEl.style['max-width'] = '930px';
          footerPlacementEl.style['max-height'] = '180px';
        }
      }

      if (asidePlacementEl) {
        asidePlacementEl.style['max-height'] = '1050px';
        if (width > 1330) {
          if (width <= 1624) {
            asidePlacementEl.style['max-width'] = '160px';
          }
        } else {
          asidePlacementEl.style['display'] = 'none';
        }
      }
    });
  }

  _updatePlacementsForDashboardPage(width) {
    log.debug('Ads::_updatePlacementsForDashboardPage() called');
    const maxWidth = width > 970 ? 970 : width;
    let sizeLimitationFunction = (sizes) => {
      sizes = _.filter(sizes, size => !_.isArray(size) || size[0] == 1 ? size : size[0] <= maxWidth);
      if (sizes.length == 1 && sizes[0] == 1) return [];
      return sizes;
    };
    this.sizeLimitationFuncs['footer'] = sizeLimitationFunction;
  }

  fill() {
    log.debug('Ads::fill() called');
    if (this.isAdsDisabled()) return;
    this.dfp.fillPlacementsWithAds(this.pageConfig.name);
    // if (DEBUG) {
    //   createDebugOverlays();
    // }
  }
  refreshAll(overrideLock = false, targetingKeyValMap = {}, optionalRefreshLockTime) {
    log.debug('Ads::refreshAll() called');
    if (this.isAdsDisabled()) return;
    this.dfp.refreshAll(overrideLock, targetingKeyValMap, optionalRefreshLockTime);
  }

  refresh(element_id, override, keyVals) {
    log.debug('Ads::refresh() called');
    if (this.isAdsDisabled()) return;
    this.dfp.refreshById(element_id, override, keyVals);
  }

  refreshByAdUnitName(adUnitName, override, keyVals) {
    log.debug('Ads::refreshByAdUnitName() called');
    if (this.isAdsDisabled()) return;
    this.dfp.refreshByAdUnitName(adUnitName, override, keyVals);
  }


  _getDfpPageConfig() {
    log.debug('Ads::_getDfpPageConfig() called');
    const isMobile = Utils.isMobileByWidth();
    let config = _.cloneDeep(dfp_config);
    let all = _.head(config.pages.filter(page => {
      return page.name === '__ALL__';
    }));
    let res = _.head(config.pages.filter(page => {
      if (page.urlRegex) {
        let found = false;
        page.url.forEach(url => {
          if (found) return found;
          let exp = new RegExp(url);
          found = exp.test(location.pathname);
        });
        return found;
      } else {
        return page.url.indexOf(location.pathname) > -1;
      }
    }));
    if (_.isNil(res)) res = all;
    if (isMobile) {
      res.placements = res.mobile.placements;
      res.ad_unit_defaults = res.mobile.ad_unit_defaults;
    } else {
      res.placements = res.desktop.placements;
      res.ad_unit_defaults = res.desktop.ad_unit_defaults;
    }
    if (all) {
      if (isMobile) {
        all.placements = all.mobile.placements;
        all.ad_unit_defaults = all.mobile.ad_unit_defaults;
      } else {
        all.placements = all.desktop.placements;
        all.ad_unit_defaults = all.desktop.ad_unit_defaults;
      }
      if (res.ad_unit_defaults === undefined) res.ad_unit_defaults = all.ad_unit_defaults;
      if (res.placements === undefined) {
        res.placements = all.placements;
      } else {
        Object.keys(all.placements).forEach(key => {
          if (res.placements[key]) return;
          res.placements[key] = all.placements[key];
        });
      }
    }
    res.is_mobile = isMobile;
    log.info('page:', res);
    return res;
  }

  getFloor(adUnitElemId) {
    return _.result(this.dfp, `idsToAdUnits[${adUnitElemId}].floor`, _.result(dfp_config, 'options.default_floor', 0.03));
  }

  isAdsDisabled() {
    let shouldDisable = Utils.isAdsDisabled()
    if (shouldDisable) return true;
    const country = getCountry();
    const countries_blacklist = _.result(config, 'options.blacklist_isos', []);
    if (_.includes(countries_blacklist, country)) return true
    return false;
  }

  async bind(sizeLimitFunc) {
    log.debug('Ads::bind() called');
    if (this.isAdsDisabled()) return;
    if (config.options.is_consent_enabled) {
      await this.init();
    }
    this.pageConfig = this._getDfpPageConfig();
    const screen_width = Utils.getWindowWidth();
    this._createOrUpdatePlacementsForPage();
    let topMaxWidth = screen_width;
    const topPlacementEl = document.querySelector('#pomad-placement-top-1');
    _.forEach(this.pageConfig.placements, (placement, placementName) => {
      _.forEach(placement.ad_units, adUnit => {
        let sizes = adUnit.sizes;
        if (_.isEmpty(sizes)) sizes = this.pageConfig.ad_unit_defaults.sizes;
        sizes = _.filter(sizes, size => (!_.isArray(size) || size[0] == 1 || size[0] <= screen_width));
        //global size limitations function override
        if (sizeLimitFunc && _.isFunction(sizeLimitFunc)) {
          sizes = sizeLimitFunc(placementName, sizes);
        }
        //specific dynamic placement function size override
        const sizeLimitFuncForPlacement = this.sizeLimitationFuncs[placementName];
        if (sizeLimitFuncForPlacement && _.isFunction(sizeLimitFuncForPlacement)) {
          sizes = sizeLimitFuncForPlacement(sizes);
        }
        adUnit.sizes = sizes;

        switch (placementName) {
          case 'top': {
            if (this.pageConfig.name === 'nQuiz' && topPlacementEl && sizes && sizes.length > 0) {
              topPlacementEl.style['min-height'] = '105px';
            }
          }
            break;
          case 'aside_left': {
            const asideLeftPlacementEl = document.querySelector('#pomad-placement-aside_left-2');
            if (asideLeftPlacementEl) {
              if (screen_width > 1572) {
                asideLeftPlacementEl.style['min-width'] = '300px';
                if (sizes.length > 0) {
                  topMaxWidth = screen_width - 300;
                }
              } else {
                if (sizes.length > 0 && screen_width >= 1330) {
                  topMaxWidth = screen_width - 160;
                }
                asideLeftPlacementEl.style['min-width'] = '160px';
              }
            }
          }
            break;
          case 'aside_right': {
            const asideRightPlacementEl = document.querySelector('#pomad-placement-aside_right-5');
            if (asideRightPlacementEl) {
              if (screen_width > 1572) {
                asideRightPlacementEl.style['min-width'] = '300px';
                if (sizes.length > 0) {
                  topMaxWidth = screen_width - 300;
                }
              } else {
                asideRightPlacementEl.style['min-width'] = '160px';
                if (sizes.length > 0 && screen_width >= 1330) {
                  topMaxWidth = screen_width - 160;
                }
              }
            }
          }
            break;
        }
        if (config.is_hb && _.isNil(this.dfp.ascendium)) {
          adUnit.bidders = generateAllBidders(adUnit, sizes);
        }
      });
    });

    if (this.pageConfig.name === 'nQuiz' && topPlacementEl) {
      if (topMaxWidth > 728) {
        topPlacementEl.style['min-width'] = '728px';
      } else {
        topPlacementEl.style['min-width'] = `${topMaxWidth}px`;
        topPlacementEl.style['max-width'] = `${topMaxWidth}px`;
      }
    }
    const footerPlacementEl = document.querySelector('#pomad-placement-footer-6');
    if (footerPlacementEl) {
      let leftSideWidth = 0;
      let nextBtnWidth = 0;
      let offsetPadding = 80;
      if (this.pageConfig.name !== 'nResults') {
        const studentInitialLevel = _.result(bridge, 'session.user.student.initialLevel');
        const el = document.querySelector('.quiz-action-panel__markers');
        if (el) {
          leftSideWidth = studentInitialLevel ? el.offsetWidth : 288;
        }
        if (this.pageConfig.name === 'review') {
          const el = document.querySelector('.quiz-action__review-btn.next-btn')
          if (el) {
            nextBtnWidth = el.offsetWidth * 2;
          }
        }
      } else {
        offsetPadding = 36;
      }
      let availableFooterAdsWidth = topMaxWidth - (leftSideWidth + nextBtnWidth) - offsetPadding;
      if (availableFooterAdsWidth > 728 && this.pageConfig.name !== 'review') {
        footerPlacementEl.style['min-width'] = '728px';
      } else {
        availableFooterAdsWidth = availableFooterAdsWidth < 468 ? availableFooterAdsWidth : 468;
        if (this.pageConfig.name !== 'review') {
          footerPlacementEl.style['min-width'] = `${availableFooterAdsWidth}px`;
        }
        footerPlacementEl.style['max-width'] = `${availableFooterAdsWidth}px`;
      }
    }

    if (this.pageConfig === null) {
      log.warning('no ad page config found for this page');
      return;
    }

    this.dfp.setPlacements(this.pageConfig.placements);
    await this.dfp.init();
    //setTimeout(() => {
    this.dfp.fillPlacementsWithAds(this.pageConfig.name);
    if (this.isLazy) {
      const existingPlacementsEl = document.querySelectorAll('.pomad-placement');
      _.forEach(existingPlacementsEl, currEl => {
        this.observer.observe(currEl);
      });
    }
    if (_.result(config, 'options.auto_ads', false)) {
      if (window.location.pathname === '/app/student/quiz' || window.location.pathname === '/app/student/report') {
        setTimeout(this.loadAutoAds.bind(this), 5000)
      } else {
        this.loadAutoAds();
      }
    }
    if (this.dfp.ascendium) {
      this.dfp.displayRemainingAds();
    }
    // if (DEBUG) {
    //   createDebugOverlays();
    // }
    //}, 0);
  }

  loadInterstitialAd() {
    if (this.isAdsDisabled()) return;
    if (config.options.interstitial) this.dfp.loadInterstitialAd()
  }

  destoryAds() {
    log.debug('Ads::destoryAds() called');
    if (this.isAdsDisabled()) return
    this.dfp.destroySlots();
  }

  loadAutoAds() {
    log.debug('Ads::loadAutoAds() called');
    const autoAdsConfig = {}
    if (dfp_config.is_coppa) {
      autoAdsConfig.tagForChildDirectedTreatment = '1';
    }
    const adsenseNetworkCode = _.result(dfp_config, 'options.adsense_id', 'pub-5842772315688199')
    const autoAdsScriptAttribs = { 'crossorigin': 'anonymous' }
    Utils.loadScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-'+adsenseNetworkCode, true, autoAdsConfig, autoAdsScriptAttribs);
  }
}

const dfp = new Dfp(dfp_config.id, false, config.is_hb);
const instance = new Ads(false, dfp);//isIntersectionObserverSupported);
window.rt = window.rt || {};
window.rt.client = window.rt.client || {};
window.rt.client.fill = instance.fill.bind(instance);
window.rt.client.refresh = instance.refresh.bind(instance);
window.rt.client.refreshByName = instance.refreshByAdUnitName.bind(instance);
window.rt.client.getFloor = instance.getFloor.bind(instance);
export default instance;
