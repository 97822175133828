import Utils from './Utils';
import {isCoppa} from './coppa';

class GTag {

  constructor() {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
      window.dataLayer.push(arguments);
    }
    window.gtag('js', new Date());
    window.gtag('config', 'AW-322370504');
  }

  loadScript() {
    if (!isCoppa()) {
      Utils.loadScript('https://www.googletagmanager.com/gtag/js?id=AW-322370504')
    }
  }

  fireConversion() {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-322370504/2ChwCN_ZofICEMj325kB',
        'value': 1.0,
        'currency': 'USD',
        'aw_remarketing_only': true
      })
    }
  }

}

export const RTGtag = new GTag();
