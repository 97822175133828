import log from 'common/Logger';
import {isStudent, isSubscriptionActive} from './UserService';
import Utils from './Utils';
import abtest from "./abtest";
const CLARITY_PROJECT_ID = 'av8ur36go5'

/**
 * Easier implementation for MS Clarity Hotmaps integration
 */
class Clarity {

  constructor(clarity_project_id) {
    this._initPromise = null;
    this._isInit = false;
    this.project_id = clarity_project_id;
  }

  init() {
    if (!this._isInit) {
      if (this._initPromise) return this._initPromise;
      const self = this;
      this._initPromise = new Promise((resolve, reject) => {
        (function(c, l, a, r, i, t, y) {
          c[a] = c[a] || function() {
            (c[a].q = c[a].q || []).push(arguments)
          };
          t = l.createElement(r);
          t.async = 1;
          t.src = "https://www.clarity.ms/tag/" + i;
          //added this to original clarity script to resolve/reject promise
          t.onreadystatechange = function() {
            if (this.readyState === 'complete') {
              self._isInit = true;
              resolve();
            }
          }
          t.onload = function() {
            self._isInit = true;
            resolve();
          };
          t.onerror = function() {
            reject();
          };
          //end of custom code
          y = l.getElementsByTagName(r)[0];
          y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", this.project_id);
      })
      return this._initPromise;
    }
    else return this._initPromise

  }

  addCustomTag(key, value) {
    if (window.clarity) window.clarity("set", key, value)
  }

  async load() {
    try {
      await this.init();
      log.debug('ms clarity loaded')
      this.addCustomTag('role', isStudent() ? 'student' : 'teacher')
      this.addCustomTag('isPremium', isSubscriptionActive() ? 'true' : 'false')
      this.addCustomTag('abTest', abtest.abTestId)
      this.addCustomTag('abTestVar', abtest.uid)
      const ref = Utils.getRef()
      if (ref) {
        this.addCustomTag('ref', ref)
      }
    }
    catch (e) {
      log.warn('could not load clarity');
    }
  }
}

export const RTClarity = new Clarity(CLARITY_PROJECT_ID)

