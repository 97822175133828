import _ from 'lodash'

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const cleanState = _.omit(state, ['classes'])
        const serializedState = JSON.stringify(cleanState);
        localStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};

export const clearState = () => {
    localStorage.removeItem('state');
}
