import abtest from 'common/abtest';
import _ from 'lodash';
const default_openx_config = {
    active: true,

    'all': {
        delDomain: 'readtheory-d.openx.net',
    },

    'RT_MyProgress_D970x250_1': {
        unit: '540752266'
    },

    'RT_Quiz_D336x260_1': {
        unit: '540752267'
    },

    'RT_Quiz_D_2': {
        unit: '540752267'
    },

    'RT_Quiz_D_3': {
        unit: '540752267'
    },

    'RT_Quiz_T_1': {
        unit: '540752268'
    },

    'RT_Result_D970x250_1': {
        unit: '540752269'
    },

    'RT_Quiz_D_Left': {
        unit: '540752265'
    }
};
export const openx_config = _.result(abtest, 'adConfig.config.prebid.openx', default_openx_config);
