import DEBUG from 'common/debug';
import _ from 'lodash';
import NetworkWorker from './network.worker'
import {getUserId, sessionId} from './UserService';
import {RT_ANALYTICS_API} from '../config';
import bridge from './bridge';
import abtest from './abtest'
import {DateTime} from 'luxon';

const LOG_LEVELS = {
    ALWAYS: -999,
    //ALERT: 1,
    //CRITICAL: 2,
    ERROR: 3,
    WARNING: 4,
    //NOTICE: 5,
    INFO: 6,
    DEBUG: 7,
    //TRACE: 8,
    NONE: 999,
};
const LOG_LEVELS_TO_STRING = {
    '-999': '*INFO',
    '3': 'ERROR',
    '4': 'WARNING',
    '6': 'INFO',
    '7': 'DEBUG',
};

class Logger {

    constructor(level = LOG_LEVELS.WARNING, reportLevel = LOG_LEVELS.WARNING) {
        this._worker = new NetworkWorker();
        this._worker.postMessage({
            _options: {
                url: `${RT_ANALYTICS_API}/logs`
            }
        });
        this.level = level;
        this.reportingLevel = reportLevel;
    }

    getElapsedTime() {
        let ts = '';
        if (performance && performance.now) {
            ts = (performance.now()/1000).toFixed(2);
        }
        return ts;
    }




    log(level, args) {
        let sendToServer = level === LOG_LEVELS.ALWAYS ||
          level > 0 && this.reportingLevel >= level;
        if (sendToServer) {
            let payload = {
                created_at: DateTime.local().setZone('America/New_York').toISO({ includeOffset: false }),
                elapsed_milis: Math.round(performance.now()),
                //step: this._stepCounter++,
                message: _.head(args),
                args: JSON.stringify(_.tail(args)),
                log_level: level,
                client_ver: $$VERSION$$,
                server_ver: bridge.version,
                ab_test_uid: abtest.uid,
                user_id: getUserId(),
                session_id: sessionId,
                href: window.location.href
            };
            this._worker.postMessage(payload)
        }
        if (this.level >= level) {
            const msg = this.decorateLog(args, (sendToServer ? '*' : '')+`[${LOG_LEVELS_TO_STRING[''+level]}][${this.getElapsedTime()}s]:`);
            console.log.apply(console, msg);
        }
    }


    decorateLog(args, prefix) {
        args = [].slice.call(args);
        prefix && args.unshift(prefix);
        args.unshift('display: inline-block; color: #fff; background: #3b88c3; padding: 1px 4px; border-radius: 3px;');
        args.unshift('%cRT');
        return args;
    }

    debug(...args) {
        this.log(LOG_LEVELS.DEBUG, args);
    }

    info(...args) {
        this.log(LOG_LEVELS.INFO, args);
    }

    warn(...args) {
        this.log(LOG_LEVELS.WARNING, args);
    }

    error(...args) {
        this.log(LOG_LEVELS.ERROR, args);
    }

    always(...args) {
        this.log(LOG_LEVELS.ALWAYS, args);
    }

}

const instance = new Logger(DEBUG ? LOG_LEVELS.DEBUG : LOG_LEVELS.WARNING);
export default instance;
