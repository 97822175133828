import dfp_config from 'ads/dfp/dfp_config';
import log from 'common/Logger';
import Utils from "../../common/Utils";
import _ from "lodash";
import {isCoppa} from "../../common/coppa";

export default class Ascendium {

  constructor() {
    window.pbjs = window.pbjs || {};
    pbjs.que = pbjs.que || [];
  }

  push(func) {
    window.pbjs.que.push(func);
  }

  setPlacements(placements) {

  }

  async init() {
    log.info('loading ascendium script');
    let scriptFile = '/static/assets/app/asc.readtheory.js';
    if (isCoppa()) {
      scriptFile = '/static/assets/app/asc.readtheory.coppa.js';
    }
    Utils.loadScript(scriptFile).then(() => {
      log.info('ascendium loaded');
      if (dfp_config.is_coppa) {
        window.pbjs.setPrivacySettings({
          childDirectedTreatment: true,
          underAgeOfConsent: true
        });
      }
    }).catch((e) => {
      log.error('failed to load ascendium', e);
    })
  }


  // * => []
  _getPlacementElementsFromPage() {
    let placementEls = document.querySelectorAll('.pomad-placement');
    placementEls = _.filter(placementEls, currEl => {
      const computedStyle = getComputedStyle(currEl) || {};
      return currEl.dataset.pmdPlacementLoaded !== '1' && computedStyle.display !== 'none';
    });
    return placementEls;
  }

  displaySingleAd(placementEl, retry = true, pageName = null) {
    this.push(function() {
      window.pbjs.processAdsOnPage();
    })
  }

  /**
   * Refreshes by name if timeout hasn't expired
   * @param id
   */
  refreshByAdUnitName(name, overrideLock = false, targetingKeyValMap = {}) {
    this.push(function() {
      window.pbjs.refreshSlot(name);
    })
  }

  refreshAll(overrideLock = false, targetingKeyValMap = {}, optionalRefreshLockTime) {
    this.push(function() {
      window.pbjs.refreshAllSlots();
    })
  }

  /**
   * Refreshes by id if timeout hasn't expired
   * @param id
   */
  refreshById(id, overrideLock = false, targetingKeyValMap = {}) {
    this.push(function() {
      window.pbjs.refreshById(id);
    })
  }


  displayRemainingAds() {
    this.push(function() {
      window.pbjs.processAdsOnPage();
    })
  }

  enableServices() {
  }

  destroySlots() {
    this.push(function() {
      window.pbjs.destroyAllSlots();
    })
  }

  createDfpAdEl(adUnit, el) {
    log.info('adding asc data elements')
    let networkCode = dfp_config.id;
    if (adUnit.network) {
      networkCode = adUnit.network;
    }
    el.dataset.aaAdunit = '/' + networkCode + '/' + adUnit.name;
    el.dataset.aaad = 'true';
    el.dataset.ad = adUnit.name;
  }

}
