import {isCoppa} from 'common/coppa';
import {sovrn_config} from './sovrn_config';
import _ from 'lodash'

export function generateBidders(adUnit, sizes) {
  const coppa = isCoppa();
  const config = _.result(sovrn_config, `[coppa__${coppa}][${adUnit.name}]`, null);
  if (_.isEmpty(config)) return [];
  else {
    return _.reduce(sizes, (bidders, size, idx) => {
      if (_.isArray(size) && size.length > 1) {
        const curr_size_config = config[`${size[0]}x${size[1]}`]
        if (_.isObject(curr_size_config)) {
          bidders[`sovrn__${idx}`] = {
            ...curr_size_config,
            size: size
          }
        }
      }
      return bidders;
    }, {});
  }
}
