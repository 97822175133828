import dfp_config from 'ads/dfp/dfp_config';
import log from 'common/Logger';
import Utils from 'common/Utils';
// import AdsAnalytics from 'ads/AdsGoogleAnalytics';
import RTGoogleAnalytics from 'common/RTGoogleAnalytics';
import RTGoogleAnalyticsManager from 'common/RTGoogleAnalyticsManager';
import _ from 'lodash';
import {E_BIDS_READY} from '../hb/hb_events';
import Ascendium from "../ascendium/Ascendium";
// import {isCoppa} from '../../common/coppa';
// import {getCountry} from '../../common/UserService';

export default class Dfp {

    constructor(dfpAccountId = dfp_config.id, isLazyLoad = false, hbEnabled = false) {
        this.SA_LINEITEM_IDS = _.result(dfp_config, `options.video_line_items`, [
            //super awesome
            5559955088,
            5577334798,
            5563948237,
            5562812823,
            5576229351,
            //primis
            5877574270,
            5890339102
        ]);
        this.isFirstTime = true;
        this.refreshLocks = {};
        this.refreshTimeoutCallbacks = {};
        this.doSidebarLockHack = true; //hack to lock the quiz sidebar unit for 45 seconds minimum on first refresh
        this.id = dfpAccountId;
        this.dfpDisplayTags = [];
        this.idsToSlots = {};
        this.idsToAdUnitNames = {};
        this.idsToAdUnits = {};
        this.idsToRefreshCounters = {};
        this.isLazyLoad = isLazyLoad;
        this.placements = {};
        this.placementsObservers = [];
        this.loadInterstitialAd = _.once(this._loadInterstitialAd)
        const isAscendium = _.result(dfp_config, 'options.ascendium_ads_enabled', false);
        if (isAscendium) this.ascendium = new Ascendium()
        this.isHB = hbEnabled && !isAscendium;
    }

    setPlacements(placements) {
        log.debug('DFP::setPlacements() called');
        this.placements = placements;
    }

    async init() {
        log.debug('DFP::init() called');
        if (this.ascendium) {
            window.googletag = window.googletag || {};
            googletag.cmd = googletag.cmd || [];
            log.info('ascendium 3rd party enabled, loading ascendium script');
            return await this.ascendium.init();
        } else {
            Utils.loadScript('https://securepubads.g.doubleclick.net/tag/js/gpt.js')
                .then(() => {
                    RTGoogleAnalyticsManager.setAdBlocked(false)
                })
                .catch(e => {
                    RTGoogleAnalyticsManager.setAdBlocked(true)
                })
            window.googletag = window.googletag || {};
            googletag.cmd = googletag.cmd || [];
            if (this.isHB) {
                const module = await import('ads/hb/hb');
                const HB = module.default;
                this.hb = new HB();
            }
            const self = this;
            googletag.cmd.push(function () {
                if (self.isHB) {
                    googletag.pubads().disableInitialLoad();
                }
                if (dfp_config.is_coppa) {
                    googletag.pubads().setTagForChildDirectedTreatment(1);
                }
                googletag.pubads().collapseEmptyDivs();
                googletag.cmd.push(function () {
                    // const events = ['slotRequested', 'slotResponseReceived','slotOnload','slotRenderEnded']
                    // events.forEach(eventName => {
                    //   googletag.pubads().addEventListener(eventName, (e) => {
                    //     log.debug(`DFP Event: ${eventName}`, e);
                    //     const adUnitName = _.last(e.slot.getAdUnitPath().split('/'));
                    //     if (e.hasOwnProperty('sourceAgnosticLineItemId')) {
                    //       const lineItem = e.sourceAgnosticLineItemId || 'no fill'
                    //       AdsAnalytics.sendEvent(`DFP_${adUnitName}`, eventName, ''+lineItem);
                    //     } else {
                    //       AdsAnalytics.sendEvent(`DFP_${adUnitName}`, eventName)
                    //     }
                    //
                    //   });
                    // });
                    googletag.pubads().addEventListener('slotRenderEnded', (e) => {
                        try {
                            if (self.SA_LINEITEM_IDS.indexOf(e.lineItemId) > -1
                                || self.SA_LINEITEM_IDS.indexOf(e.sourceAgnosticLineItemId) > -1) {
                                const elemId = e.slot.getSlotElementId()
                                self.lockRefreshes(elemId, 45000)
                            }
                        } catch (e) {
                            console.log('could not lock refreshes for SA')
                        }
                    })

                    let reportFirstAdTimingOnce = _.once(function reportAdTiming(e) {
                        const adUnitName = _.last(e.slot.getAdUnitPath().split('/'));
                        RTGoogleAnalytics.sendTiming('Ads', 'dfp_first_ad', adUnitName);
                        // googletag.pubads().removeEventListener('slotRenderEnded', reportFirstAdTiming);
                    });
                    googletag.pubads().addEventListener('slotRenderEnded', reportFirstAdTimingOnce);

                })
            });
        }
    }


    // * => []
    _getPlacementElementsFromPage() {
        log.debug('DFP::init() _getPlacementElementsFromPage() called');
        let placementEls = document.querySelectorAll('.pomad-placement');
        placementEls = _.filter(placementEls, currEl => {
            const computedStyle = getComputedStyle(currEl) || {};
            return currEl.dataset.pmdPlacementLoaded !== '1' && computedStyle.display !== 'none';
        });
        return placementEls;
    }

    displaySingleAd(placementEl, retry = true, pageName = null) {
        log.debug('DFP::displaySingleAd() called');
        if (this.ascendium) {
            return;
        }

        if (placementEl && this.idsToSlots[placementEl.id]) {
            googletag.cmd.push(function () {
                googletag.display(placementEl.id);
                placementEl.dataset.pmdDisplay = '1';
            });
        } else if (retry) {
            this.fillPlacementsWithAds(pageName);
            this.displaySingleAd(placementEl, false, pageName);
        }

    }

    /**
     * Refreshes by name if timeout hasn't expired
     * @param id
     */
    refreshByAdUnitName(name, overrideLock = false, targetingKeyValMap = {}) {
        log.debug('DFP::refreshByAdUnitName() called');
        if (this.ascendium) {
            return this.ascendium.refreshByAdUnitName(name, overrideLock, targetingKeyValMap)
        }
        log.debug(`refreshing ${name}...`);
        const namesToIds = _.invert(this.idsToAdUnitNames);
        const id = namesToIds[name];
        this.refreshById(id, overrideLock, targetingKeyValMap)
    }

    refreshAll(overrideLock = false, targetingKeyValMap = {}, optionalRefreshLockTime) {
        log.debug('DFP::refreshAll() called');
        if (this.ascendium) {
            return this.ascendium.refreshAll(overrideLock, targetingKeyValMap, optionalRefreshLockTime)
        }
        const self = this;
        const slots = []
        log.debug(`refreshing all placements:`);
        _.forEach(_.keys(this.idsToSlots), (id) => {
            log.debug(`refreshing ${id}...`);
            if (dfp_config.refresh_timeout < 0 && !overrideLock) {
                log.debug(`cannot refresh id ${id} - refresh disabled`);
                return;
            }
            const locked = this.refreshLocks[id] === true;
            if (locked && !overrideLock) {
                log.debug(`cannot refresh (id ${id} is currently locked)`);
                return;
            }
            const slot = this.idsToSlots[id]
            if (slot) {
                _.isNumber(this.idsToRefreshCounters[id]) ? this.idsToRefreshCounters[id]++ : this.idsToRefreshCounters[id] = 1;
                googletag.cmd.push(function () {
                    targetingKeyValMap['refresh_count'] = self.idsToRefreshCounters[id];
                    _.forEach(targetingKeyValMap, (val, key) => {
                        slot.setTargeting(key, val);
                    });
                    self.lockRefreshes(id, optionalRefreshLockTime);
                    slots.push(slot)
                })
            } else {
                log.info(`could not refresh ${id}, slot not found!`);
            }
        })
        if (self.isHB) {
            log.debug(`PBJS -> refreshing ${slots.length} ads`);
            self.hb.refreshBids(slots);
        } else {
            googletag.cmd.push(function () {
                log.debug(`DFP -> refreshing ${slots.length} ads`);
                googletag.pubads().refresh(slots);
            })
        }
    }

    /**
     * Refreshes by id if timeout hasn't expired
     * @param id
     */
    refreshById(id, overrideLock = false, targetingKeyValMap = {}) {
        log.debug('DFP::refreshById() called');
        if (this.ascendium) {
            return this.ascendium.refreshById(id)
        }
        log.debug(`refreshing ${id}...`);
        if (dfp_config.refresh_timeout < 0 && !overrideLock) {
            log.debug(`cannot refresh id ${id} - refresh disabled`);
            return;
        }
        const locked = this.refreshLocks[id] === true;
        if (locked && !overrideLock) {
            log.debug(`cannot refresh (id ${id} is currently locked)`);
            return;
        }
        const slot = this.idsToSlots[id]
        const self = this;
        if (slot) {
            _.isNumber(this.idsToRefreshCounters[id]) ? this.idsToRefreshCounters[id]++ : this.idsToRefreshCounters[id] = 1;
            googletag.cmd.push(function () {
                targetingKeyValMap['refresh_count'] = self.idsToRefreshCounters[id];
                _.forEach(targetingKeyValMap, (val, key) => {
                    slot.setTargeting(key, val);
                });
                if (self.isHB) {
                    self.hb.refreshBid(slot);
                } else {
                    googletag.pubads().refresh([slot]);
                }
                self.lockRefreshes(id);
                // AdsAnalytics.trackAdUnit(self.idsToAdUnitNames[id], self.idsToRefreshCounters[id])
            })
        } else {
            log.info(`could not refresh ${id}, slot not found!`);
        }
    }

    /**
     * given an element id, can lock refreshes on the ads inside that div
     * @param id - the element id
     * @param optionalLockTime - optional lock time in milis or -1 for locking forever, when undefined the value is the default refresh timeout from the ad config
     */
    lockRefreshes(id, optionalLockTime = null) {
        log.debug('DFP::lockRefreshes() called');
        const lockTime = optionalLockTime ? optionalLockTime : dfp_config.refresh_timeout;
        log.debug(`locking ${id} for ${lockTime}ms`)
        if (this.refreshTimeoutCallbacks[id]) clearTimeout(this.refreshTimeoutCallbacks[id])
        this.refreshLocks[id] = true;
        const self = this;
        if (lockTime >= 0) {
            this.refreshTimeoutCallbacks[id] = setTimeout(function () {
                self.refreshLocks[id] = false
            }, lockTime);
        }
    }

    displayRemainingAds() {
        log.debug('DFP::displayRemainingAds() called');
        if (this.ascendium) {
            return this.ascendium.displayRemainingAds()
        }
        const self = this;
        googletag.cmd.push(function () {
            while (self.dfpDisplayTags.length > 0) {
                let id = self.dfpDisplayTags.shift();
                googletag.display(id);
                //placementEl.dataset.pmdDisplay = '1';
                self.lockRefreshes(id);
                // AdsAnalytics.trackAdUnit(self.idsToAdUnitNames[id])
            }
        });
    }

    enableServices() {
        log.debug('DFP::enableServices() called');
        if (this.ascendium) {
            return this.ascendium.enableServices()
        }
        const self = this;
        googletag.cmd.push(function () {
            _.forEach(dfp_config.targetingMap, (val, key) => {
                googletag.pubads().setTargeting(key, val)
            });
            if (!self.isLazyLoad) {
                googletag.pubads().enableSingleRequest();
            }
            googletag.enableServices();
        });
    }

    fillPlacementsWithAds(pageName) {
        let placementEls = this._getPlacementElementsFromPage();
        if (_.isEmpty(placementEls)) {
            log.debug('no placements found on page, skipping...');
            return;
        }
        log.debug('found these placements on page: ', placementEls)

        const currCreatedAdUnits = [];
        placementEls.forEach(el => {
            let name = el.dataset.pmdPlacementName;
            if (!_.isEmpty(_.result(this.placements, `[${name}].ad_units`, []))) {
                let currUnit = this.placements[name].ad_units.shift();
                if (!currUnit.hasAlreadyCreatedElement) {
                    currUnit.hasAlreadyCreatedElement = true;
                    this.createDfpAdEl(currUnit, el);
                    currCreatedAdUnits.push(currUnit);
                }
            }
        });
        if (this.ascendium) {
            return
        }
        if (this.isHB) {
            const self = this;
            if (this.isFirstTime) {
                this.isFirstTime = false;
                window.addEventListener(E_BIDS_READY, () => {
                    this.enableServices()
                    googletag.cmd.push(function () {
                        googletag.pubads().refresh();
                    });
                });
                const adUnitsForPrebid = this.hb.prepareAdUnitsForPrebid(currCreatedAdUnits)
                const adUnitsForAps = this.hb.prepareAdUnitsForAps(this.id, currCreatedAdUnits);
                this.hb.createWithUnits(adUnitsForPrebid, adUnitsForAps);
            } else {
                self.hb.refreshBids(_.map(currCreatedAdUnits, adUnit => self.idsToSlots[adUnit.selector]), currCreatedAdUnits)
            }
        } else {
            if (this.isFirstTime) {
                this.isFirstTime = false;
                this.enableServices();
            }
            if (!this.isLazyLoad) {
                this.displayRemainingAds();
            }
        }
        this.addPlacementsObservers(pageName);
    }

    addPlacementsObservers(pageName) {
        log.debug('DFP::addPlacementsObservers() called');
        this.placementsObservers.forEach(observer => observer.disconnect());
        this.placementsObservers = [];
        const placements = document.querySelectorAll('.pomad-placement');
        const config = {attributes: true, childList: true, subtree: true};
        const callback = function (mutationsList, observer) {
            for (const mutation of mutationsList) {
                if (mutation.type === 'attributes') {
                    const targetEl = mutation.target;
                    if (targetEl.tagName === 'IFRAME') {
                        const adsPlacement = targetEl.closest('.pomad-placement');
                        if (adsPlacement) {
                            if (!adsPlacement.classList.contains('show-text')) {
                                adsPlacement.classList.add('show-text');
                                if (!Utils.isMobileByWidth() && pageName === 'myProgress' && (adsPlacement.dataset.pmdPlacementName === 'mid-top' || adsPlacement.dataset.pmdPlacementName === 'footer')) {
                                    if (adsPlacement.dataset.pmdPlacementName !== 'footer') {
                                        adsPlacement.style['max-width'] = '930px';
                                    }
                                    adsPlacement.style['max-height'] = '180px';
                                }
                            }
                        }
                    }
                }
            }
        };

        if (placements.length > 0) {
            placements.forEach(placementEl => {
                const observer = new MutationObserver(callback);
                observer.observe(placementEl, config);
                this.placementsObservers.push(observer);
            });
        }
    }

    destroySlots() {
        log.debug('DFP::destroySlots() called');
        if (this.ascendium) {
            return this.ascendium.destroySlots();
        }
        googletag.cmd.push(function () {
            googletag.destroySlots();
        });
    }

    createDfpAdEl(adUnit, el) {
        log.debug('DFP::createDfpAdEl() called');
        const self = this;
        adUnit.selector = el.id;
        self.idsToAdUnitNames[el.id] = adUnit.name;
        self.idsToAdUnits[el.id] = adUnit;
        googletag.cmd.push(function () {
            el.dataset.pmdPlacementLoaded = 1;
            el.dataset.pmdPlacementType = 'DFP';
            el.dataset.pmdAdUnitName = adUnit.name;
            log.debug(`creating ad unit ${adUnit.name} in element #${el.id} with sizes:`, adUnit.sizes)
            let networkCode = self.id;
            if (adUnit.network) {
                networkCode = adUnit.network;
            }
            if (self.ascendium) {
                self.ascendium.createDfpAdEl(adUnit, el);
                return;
            }

            let slot = googletag.defineSlot('/' + networkCode + '/' + adUnit.name, adUnit.sizes, el.id)
                .addService(googletag.pubads())
                .setTargeting('refresh_count', '0');
            self.idsToSlots[el.id] = slot;
            self.dfpDisplayTags.push(el.id);
        });
    }

    _loadInterstitialAd() {
        log.debug('DFP::_loadInterstitialAd() called');
        const self = this;
        log.debug('loading interstitial')
        googletag.cmd.push(function () {
            let slot = googletag.defineOutOfPageSlot('/' + self.id + '/Readtheory_AdX_Interstitial', googletag.enums.OutOfPageFormat.INTERSTITIAL);
            if (slot) slot.addService(googletag.pubads());
        })
    }

}
