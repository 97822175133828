import _ from 'lodash';
import abtest from '../../../common/abtest';

const default_conversant_config = {
  active: false,
  coppa__false: {
    'all': {
      site_id: '208936',
      secure: 1
    },
  },
  coppa__true: {}
};
export const conversant_config = _.result(abtest, 'adConfig.config.prebid.conversant', default_conversant_config);
