import abtest from 'common/abtest';
import _ from 'lodash';
const default_sharethrough_config = {
    active: true,

    'RT_MyProgress_D970x250_1': {
        pkey: '5bRlGnpm9fOMTxdfuZE2Kq0O'
    },

};
export const sharethrough_config = _.result(abtest, 'adConfig.config.prebid.sharethrough', default_sharethrough_config);
