import {sharethrough_config} from './sharethrough_config';
import _ from 'lodash'

export function generateBidders(adUnit, sizes) {
  const config = _.result(sharethrough_config, `${adUnit.name}`, null);
  if (_.isEmpty(config)) return {};
  return {
    sharethrough: {
      ...config,
    },
  }
}
