import _ from 'lodash';
//import Utils from 'common/Utils'
import bridge from 'common/bridge'

const abtest = _.result(bridge, 'session.ab_var', {})
abtest.uid = _.isNumber(abtest.abTestId) ? abtest.abTestId+'_'+abtest.id : 'unknown';
// if (abtest.uid == '10_10' || abtest.uid == '9_9' && localStorage.getItem('abt_reset') !== '2') {
//   let rand_ab = _.sample(['10_10', '10_12', '9_9', '9_11']);
//   //abtest.uid = `${rand_ab}_${rand_ab}`;
//   abtest.uid = rand_ab;
//   Utils.setCookie('rt_abt', abtest.uid, 14)
//   localStorage.setItem('abt_reset', '2');
// }
export default abtest;
