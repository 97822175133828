import _ from 'lodash';
import log from 'common/Logger';
import bridge from 'common/bridge';
import abtest from 'common/abtest';
import Utils from 'common/Utils';
import {getCreatedDateEpoch, getLastLoginDateEpoch, getUserSettings} from '../common/UserService';

let extra_attribs = {};
let loaded = false;

// function addScriptTag() {
//   var elm = document.createElement('script');
//   elm.type = 'text/javascript', elm.async = !0,
//     // e.src=`https://widget.customerly.io/widget/${window.customerlySettings.app_id}`;
//     elm.src = `https://messenger.customerly.io/launcher.js`;
//   var firstScriptElm = document.getElementsByTagName('script')[0];
//   firstScriptElm.parentNode.insertBefore(elm, firstScriptElm);
// }


function loadCustomerlyScript(params) {
  loaded = true;
  !function() {
    var e = window, i = document, t = 'customerly', n = 'queue', o = 'load', r = 'settings', u = e[t] = e[t] || [];
    if (u.t) {
      return void u.i('[customerly] SDK already initialized. Snippet included twice.');
    }
    u.t = !0;
    u.loaded = !1;
    u.o = ['event', 'attribute', 'update', 'show', 'hide', 'open', 'close'];
    u[n] = [];
    u.i = function(t) {
      e.console && !u.debug && console.error && console.error(t);
    };
    u.u = function(e) {
      return function() {
        var t = Array.prototype.slice.call(arguments);
        return t.unshift(e), u[n].push(t), u;
      };
    };
    u[o] = function(t) {
      u[r] = t || {};
      if (u.loaded) {
        return void u.i('[customerly] SDK already loaded. Use `customerly.update` to change settings.');
      }
      u.loaded = !0;
      var e = i.createElement('script');
      e.type = 'text/javascript', e.async = !0, e.src = 'https://messenger.customerly.io/launcher.js';
      var n = i.getElementsByTagName('script')[0];
      n.parentNode.insertBefore(e, n);
    };
    u.o.forEach(function(t) {
      u[t] = u.u(t);
    });
  }();

  customerly.load(params);

  // !function() {
  //   var
  //     n = function() {
  //       n.c(arguments);
  //     };
  //   window.customerly_queue = [],
  //     n.c = function(e) {
  //       window.customerly_queue.push(e);
  //     },
  //     window.customerly = n,
  //     window.attachEvent ? window.attachEvent('onload', addScriptTag) : window.addEventListener('load', addScriptTag, !1);
  // }();
}

export function loadCustomerly() {
  const user = _.result(bridge.session, 'user', {}) || {};
  const userRole = _.result(bridge.user, 'role', null);
  window.customerlySettings = {
    app_id: '4a8b2a36',
    email: user.email,
    user_id: user.id,
    attributes: {
      'role': userRole,
      'firstname': _.capitalize(user.firstName),
      'lastname': _.capitalize(user.lastName),
      'username': user.username,
      'fullname': Utils.getFullname(user),
      'last_login_date': getLastLoginDateEpoch(),
      'create_date': getCreatedDateEpoch(),
      'ab_test': abtest.abTestId,
      'ab_test_var': abtest.uid,
      ...extra_attribs,
    }
  };
  const userSettings = getUserSettings();
  if (!_.isEmpty(userSettings)) {
    if (!_.isEmpty(userSettings.subscription)) {
      window.customerlySettings.attributes.sub_active = _.result(userSettings, 'subscription.active', false);
      window.customerlySettings.attributes.sub_id = _.result(userSettings, 'subscription.sub_id', '');
      window.customerlySettings.attributes.sub_created_at = _.result(userSettings, 'subscription.start_date', '');
      window.customerlySettings.attributes.sub_product_id = _.result(userSettings, 'subscription.product_id', '');
      window.customerlySettings.attributes.sub_price_id = _.result(userSettings, 'subscription.price_id', '');
      window.customerlySettings.attributes.sub_status = _.result(userSettings, 'subscription.status', '');
    }
    if (userSettings.school_name) {
      window.customerlySettings.attributes.school_name = userSettings.school_name;
    }
    if (userSettings.job_title) {
      window.customerlySettings.attributes.job_title = userSettings.job_title;
    }
    if (userSettings.ref) {
      window.customerlySettings.attributes.ref = userSettings.ref;
    }
  }
  loadCustomerlyScript(window.customerlySettings);
}

export function loadCustomerlyForLeads() {
  if (loaded) return;
  window.customerlySettings = {
    app_id: '4a8b2a36'
  }
  loadCustomerlyScript(window.customerlySettings)
}


export function updateCustomerly(moreExtraAttribs = {}) {
  extra_attribs = _.assign(extra_attribs, moreExtraAttribs);
  if (window.customerlySettings) {
    log.debug('updating customerly', extra_attribs);
    window.customerlySettings.attributes = extra_attribs;
    window.customerly.update(window.customerlySettings)
  }
}

export function toggleCustomerly(show) {
  try {
    if (show) {
      document.querySelector('#customerly-container').style.display = 'block';
    } else {
      document.querySelector('#customerly-container').style.display = 'none';
    }
  } catch (e) {
    console.error(e);
  }
}
