import _ from 'lodash';
import { getUser } from 'common/UserService';
import log from 'common/Logger';
import DEBUG from './debug';

const user = getUser();
let userFeatureFlags = _.result(user, 'userSettings', '{"feature_flags": {}}');

try {
  userFeatureFlags = JSON.parse(userFeatureFlags)['feature_flags'];
} finally {
  if (userFeatureFlags) {
    if (DEBUG) {
      console.group('User Feature Flags');
      console.table(userFeatureFlags);
      console.groupEnd();
    }
  } else {
    userFeatureFlags = {}
    log.warn('could not pull feature flags from user settings, returning none')
  }
  userFeatureFlags.isActive = (flagName) => {
    return !!userFeatureFlags[flagName]
  }
  userFeatureFlags.get = (flagName) => {
    return userFeatureFlags[flagName];
  }
}
export default userFeatureFlags

