import _ from 'lodash';
import abtest from '../../../common/abtest';

const default_smartadserver_config = {
  active: false,
  'all': {
    siteId: '406586'
  },

};
export const smartadserver_config = _.result(abtest, 'adConfig.config.prebid.smartadserver', default_smartadserver_config);
