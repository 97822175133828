import {isCoppa} from 'common/coppa';
import {appnexus_config} from './appnexus_config';
import _ from 'lodash'

export function generateBidders(adUnit, sizes) {
  const coppa = isCoppa();
  const config = _.result(appnexus_config, `${adUnit.name}`, null);
  if (_.isEmpty(config)) return {};
  const result = config;
  if (coppa) result['user'] = {
    dnt: true,
    //language: 'EN'
  };
  if (_.isNumber(adUnit.floor) && adUnit.floor !== 0) result['reserve'] = adUnit.floor;
  return {
    appnexus: {
      ...result,
    },
  };
}
