import {isCoppa} from './coppa';
import log from 'common/Logger';
import {isLoggedIn} from './UserService';

const FB_PIXEL_ID = '1454649201559660';
const EXCLUDED_PAGES = [
  '/auth/login',
  '/app/sign-up/create-account/student',
  '/welcome/demo'
]

const PAGE_TO_EVENT_MAPPING = {
  '/app/sign-up/role': 'StartTrial',
  '/app/sign-up/create-account/student' : 'Contact',
  '/app/sign-up/create-account/teacher' : 'Contact',
  '/app/sign-up/create-account/parent' : 'Contact',
  '/app/sign-up/summary' : 'SubmitApplication',
}


class FacebookPixel {

  shouldTrack(pathname=null) {
    if (EXCLUDED_PAGES.indexOf(pathname ? pathname.toLowerCase() : window.location.pathname) > -1) return false;
    if (isLoggedIn() && isCoppa()) return false;
    return true;
  }

  trackPageView(pathname) {
    if (!this.shouldTrack(pathname)) return;
    if (!this.isInit()) this.init(pathname);
    if (window.fbq) {
      if (pathname) {
        setTimeout(function() { //give a chance to the history url to update
          window.fbq('track', 'PageView')
        }, 500)
      } else {
        window.fbq('track', 'PageView')
      }

    }
  }

  reportAddPaymentInfoEvent() {
    window.fbq('track', 'AddPaymentInfo');
  }

  reportPurchaseEvent() {
    window.fbq('track', 'Purchase', {value: 0.00, currency: 'USD'});
  }

  isInit() {
    return window.fbq ? true : false;
  }

  init(pathname=window.location.pathname) {
    pathname = pathname.toLowerCase()
    if (this.shouldTrack(pathname)) {
      !function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
      }(window, document, 'script',
        'https://connect.facebook.net/en_US/fbevents.js');
      log.debug(`init fb pixel ${FB_PIXEL_ID}`);
      window.fbq('init', `${FB_PIXEL_ID}`);
      const optionalSecondEvent = PAGE_TO_EVENT_MAPPING[pathname]
      if (optionalSecondEvent) window.fbq('track', optionalSecondEvent)
    }
  }

}
export const RTFacebookPixel = new FacebookPixel();
