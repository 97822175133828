let loadPromise = null;

export async function loadGoogleConsent() {
	// Make sure that the googlefc property exists on the window.
	window.googlefc = window.googlefc || {};
	window.googlefc.ccpa = window.googlefc.ccpa || {}
	window.googlefc.callbackQueue = googlefc.callbackQueue || [];
	if (loadPromise) return loadPromise;
	loadPromise = new Promise((resolve, reject) => {
		setTimeout(() => {
			reject('CONSENT_DATA_READY_TIMEOUT')
		}, 5000)
		window.googlefc.callbackQueue.push({
			'CONSENT_DATA_READY':
				() => {

					resolve(true)
				}
		});
		window.googlefc.callbackQueue.push({
			'AD_BLOCK_DATA_READY': function() {
				var analyticsData = {
					hitType: 'event',
					eventCategory: 'Funding Choices',
					eventAction: 'Ad Blocking Type'
				};
				switch (googlefc.getAdBlockerStatus()) {
					case googlefc.AdBlockerStatusEnum.EXTENSION_LEVEL_AD_BLOCKER:
						analyticsData.eventLabel = 'EXTENSION_LEVEL_AD_BLOCKER';
						ga('send', analyticsData);
						break;
					case googlefc.AdBlockerStatusEnum.NETWORK_LEVEL_AD_BLOCKER:
						analyticsData.eventLabel = 'NETWORK_LEVEL_AD_BLOCKER';
						ga('send', analyticsData);
						break;
					case googlefc.AdBlockerStatusEnum.NO_AD_BLOCKER:
						analyticsData.eventLabel = 'NO_AD_BLOCKER';
						ga('send', analyticsData);
						break;
				}
			}
		});
	})
	return loadPromise;
}
