import {isCoppa} from 'common/coppa';
import {ix_config} from './ix_config';
import _ from 'lodash'

export function generateBidders(adUnit, sizes) {
  const coppa = isCoppa();
  const config = _.result(ix_config, `[coppa__${coppa}][${adUnit.name}]`, null);
  if (_.isEmpty(config)) return [];
  else {
    return _.reduce(sizes, (bidders, size, idx) => {
        bidders[`ix__${idx}`] = {
          ...config,
          size: size
        }
        return bidders;
    }, {});
  }
}
