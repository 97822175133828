import abtest from 'common/abtest';
import _ from 'lodash';
const default_appnexus_config = {
  active: true,

  'RT_MyProgress_D970x250_1': {
    placementId: '15994172',
    position: 1,
  },

  'RT_Quiz_D336x260_1': {
    placementId: '15994181',
    position: 1,
  },
  'RT_Quiz_D_2': {
    placementId: '15994181',
    position: 1,
  },
  'RT_Quiz_D_3': {
    placementId: '15994181',
    position: 1,
  },
  'RT_Quiz_T_1': {
    placementId: '15994188',
    position: 1,
  },

  'RT_Result_D970x250_1': {
    placementId: '15994197',
    position: 2,
  },

  'RT_Quiz_D_Left': {
    placementId: '17024184',
    position: 1,
  }
};
export const appnexus_config = _.result(abtest, 'adConfig.config.prebid.appnexus', default_appnexus_config);
