import adConfig from 'ads/ad_config';
import _ from 'lodash';

const default_sovrn_config = {
  active: true,

  coppa__false: {
    'RT_MyProgress_D970x250_1': {
      '728x90': {
        tagid: '644745',
      },
      '970x90': {
        tagid: '644746'
      },
      '970x250': {
        tagid: '644747'
      }
    },

    'RT_Quiz_D336x260_1': {
      '300x250': {
        tagid: '644748'
      },
      '336x280': {
        tagid: '644749'
      },
      '250x250': {
        tagid: '644750'
      },
    },


    'RT_Quiz_D_2': {
      '300x250': {
        tagid: '644748'
      },
      '336x280': {
        tagid: '644749'
      },
      '250x250': {
        tagid: '644750'
      },
    },

    'RT_Quiz_D_3': {
      '300x250': {
        tagid: '644748'
      },
      '336x280': {
        tagid: '644749'
      },
      '250x250': {
        tagid: '644750'
      },
    },

    'RT_Quiz_T_1': {
      '728x90': {
        tagid: '644751'
      },
      '970x90': {
        tagid: '644752'
      },
      '970x250': {
        tagid: '644753'
      }
    },

    'RT_Result_D970x250_1': {
      '728x90': {
        tagid: '644751'
      },
      '970x90': {
        tagid: '644752'
      },
    },
    'RT_Quiz_D_Left': {
      '160x600': {
        tagid: '644756'
      },
      '120x600': {
        tagid: '644757'
      },
      '300x600': {
        tagid: '651339'
      }
    }

  },

  coppa__true: {
  //   'RT_MyProgress_D970x250_1': {
  //     '728x90': {
  //       tagid: '648355',
  //     },
  //     '970x90': {
  //       tagid: '648356'
  //     },
  //     '970x250': {
  //       tagid: '648357'
  //     }
  //   },
  //
  //   'RT_Quiz_D336x260_1': {
  //     '300x250': {
  //       tagid: '648358'
  //     },
  //     '336x280': {
  //       tagid: '648359'
  //     },
  //     '250x250': {
  //       tagid: '648360'
  //     },
  //   },
  //
  //   'RT_Quiz_D_2': {
  //     '300x250': {
  //       tagid: '648358'
  //     },
  //     '336x280': {
  //       tagid: '648359'
  //     },
  //     '250x250': {
  //       tagid: '648360'
  //     },
  //   },
  //
  //   'RT_Quiz_D_3': {
  //     '300x250': {
  //       tagid: '648358'
  //     },
  //     '336x280': {
  //       tagid: '648359'
  //     },
  //     '250x250': {
  //       tagid: '648360'
  //     },
  //   },
  //
  //   'RT_Quiz_T_1': {
  //     '728x90': {
  //       tagid: '648361'
  //     },
  //     '970x90': {
  //       tagid: '648362'
  //     },
  //     '970x250': {
  //       tagid: '648363'
  //     }
  //   },
  //
  //   'RT_Result_D970x250_1': {
  //     '728x90': {
  //       tagid: '648364'
  //     },
  //     '970x90': {
  //       tagid: '648365'
  //     },
  //   },
  //   'RT_Quiz_D_Left': {
  //     '160x600': {
  //       tagid: '648366'
  //     },
  //     '120x600': {
  //       tagid: '648367'
  //     },
  //     '300x600': {
  //       tagid: '651340'
  //     },
  //   }
  }

};
export const sovrn_config = _.result(adConfig, 'prebid.sovrn', default_sovrn_config);
