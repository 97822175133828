export const default_dfp_config = {
  id: '21719097930',
  refresh_timeout: -1,
  options: {
    default_floor: 0.03,
    lazy: false,
    auto_ads: false,
    interstitial: false,
  },
  pages: [
    {
      url: [],
      name: '__ALL__',
      desktop: {
        ad_unit_defaults: {
          sizes: [[970, 250], [970, 90], [728, 90]],
        },
        placements: [],
      },
      mobile: {
        ad_unit_defaults: {
          sizes: [[250, 250], [336, 280], [300, 250], [320, 100], [320, 50]],
        },
        placements: [],
      },
    },
    {
      url: ['/dashboard/index'],
      name: 'myProgress',
      desktop: {
        placements: {
          footer: {
            ad_units: [
              {
                name: 'RT_MyProgress_D970x250_1',
              },
            ],
          },
        },
      },
      mobile: {
        placements: {
          footer: {
            ad_units: [
              {
                name: 'RT_MyProgress_D970x250_1',
              },

            ],
          },
        },
      },
    },
    {
      url: ['^/reading/welcomeReader', '^/reading/quiz'],
      urlRegex: true,
      name: 'quiz',
      desktop: {
        placements: {
          quiz: {
            ad_units: [
              {
                name: 'RT_Quiz_D336x260_1',
                sizes: [[250, 250], [336, 280], [300, 250], [320, 100], [320, 50]],
              },
            ],
          },
        },
      },
      mobile: {
        placements: {
          quiz_mobile: {
            ad_units: [
              {
                name: 'RT_Quiz_T_1',
                sizes: [[728, 90], [320, 100], [320, 50]],
              },
            ],
          },
        },
      },
    },
    {
      url: ['/reading/reviewLastQuizResponse'],
      name: 'results',
      desktop: {
        placements: {
          footer: {
            ad_units: [
              {
                name: 'RT_Result_D970x250_1',
                sizes: [[970, 90], [728, 90]],
              },
            ],
          },
        },
      },
      mobile: {
        placements: {
          footer: {
            ad_units: [
              {
                name: 'RT_Result_D970x250_1',
                sizes: [[970, 90], [728, 90]],
              },
            ],
          },
        },
      },
    },
    {
      url: ['/app/student/quiz'],
      name: 'nQuiz',
      desktop: {
        placements: {
          top: {
            ad_units: [
              {
                name: 'RT_N_Quiz_Top_728',
                floor: "none",
                sizes: [[468, 60], [728, 90], [970, 90]]
              },
            ],
          },
          in_content: {
            ad_units: [
              {
                name: 'RT_N_Quiz_Incontent_300',
                floor: "none",
                sizes: [[250, 250], [300, 250], [320, 50], [320, 100], [336, 280]]
              },
            ],
          },
          sidebar: {
            ad_units: [
              {
                name: 'RT_N_Quiz_Sidebar_300',
                floor: "none",
                sizes: [[250, 250], [300, 250], [230, 250], [320, 100], [336, 280]]
              },
            ],
          },
          aside_left: {
            ad_units: [
              {
                name: 'RT_N_Quiz_StLeft_160',
                floor: "none",
                sizes: [[120, 600], [160, 600], [300, 600]]
              },
            ],
          },
          aside_right: {
            ad_units: [
              {
                name: 'RT_N_Quiz_StRight_160',
                floor: "none",
                sizes: [[120, 600], [160, 600], [300, 600]]
              },
            ],
          },
        },
        footer: {
          ad_units: [
            {
              name: 'RT_N_Quiz_Footer_728',
              floor: "none",
              sizes: [[468, 60], [728, 90], [970, 90]]
            },
          ],
        }
      }
    },
    {
      url: ['/app/student/quiz/review'],
      name: 'review',
      desktop: {
        placements: {
          top: {
            ad_units: [
              {
                name: 'RT_N_Review_Top_728',
                floor: "none",
                sizes: [[468, 60], [728, 90], [970, 90]]
              },
            ],
          },
          in_content: {
            ad_units: [
              {
                name: 'RT_N_Review_Incontent_300',
                floor: "none",
                sizes: [[250, 250], [300, 250], [320, 50], [320, 100], [336, 280]]
              },
            ],
          },
          sidebar: {
            ad_units: [
              {
                name: 'RT_N_Review_Sidebar_300',
                floor: "none",
                sizes: [[250, 250], [300, 250], [230, 250], [320, 100], [336, 280]]
              },
            ],
          },
          aside_left: {
            ad_units: [
              {
                name: 'RT_N_Review_StLeft_160',
                floor: "none",
                sizes: [[120, 600], [160, 600], [300, 600]]
              },
            ],
          },
          aside_right: {
            ad_units: [
              {
                name: 'RT_N_Review_StRight_160',
                floor: "none",
                sizes: [[120, 600], [160, 600], [300, 600]]
              },
            ],
          },
        },
        footer: {
          ad_units: [
            {
              name: 'RT_N_Review_Footer_728',
              floor: "none",
              sizes: [[468, 60], [728, 90], [970, 90]]
            },
          ],
        }
      }
    },
    {
      url: ['/app/student/quiz/results'],
      name: 'nResults',
      desktop: {
        placements: {
          top: {
            ad_units: [
              {
                name: 'RT_N_Results_Top_728',
                floor: "none",
                sizes: [[468, 60], [728, 90], [970, 90]]
              },
            ],
          },
          sidebar: {
            ad_units: [
              {
                name: 'RT_N_Results_Sidebar_300',
                floor: "none",
                sizes: [[250, 250], [300, 250], [230, 250], [320, 100], [336, 280]]
              },
            ],
          },
          aside_left: {
            ad_units: [
              {
                name: 'RT_N_Results_StLeft_160',
                floor: "none",
                sizes: [[120, 600], [160, 600], [300, 600]]
              },
            ],
          },
          aside_right: {
            ad_units: [
              {
                name: 'RT_N_Results_StRight_160',
                floor: "none",
                sizes: [[120, 600], [160, 600], [300, 600]]
              },
            ],
          },
        },
        footer: {
          ad_units: [
            {
              name: 'RT_N_Results_Footer_728',
              floor: "none",
              sizes: [[468, 60], [728, 90], [970, 90]]
            },
          ],
        }
      }
    }
  ],
};
