import RTGoogleAnalytics from './RTGoogleAnalytics';
import RTNewGA4Analytics from './RTNewGA4Analytics';


class RTGoogleAnalyticsManager {
  init() {
	RTGoogleAnalytics.init();
	RTNewGA4Analytics.init();
  }

  sendEvent(category, action, label, value) {
	RTGoogleAnalytics.sendEvent(category, action, label, value);
	this.sendGA4Event(category, action, label, value);
  }

  sendGA4Event(category, action, label, value) {
	const eventName = `${category}_${action}`;
	const params = {};
	if (label) {
	  params.event_label = label;
	}
	if (value) {
	  params.event_value = value;
	}
	RTNewGA4Analytics.sendEvent(eventName, params);
  }

  setCoppa(isCoppa) {
	RTGoogleAnalytics.setCoppa(isCoppa);
	RTNewGA4Analytics.setCoppa(isCoppa);
  }

  setAdBlocked(isBlocked) {
	RTGoogleAnalytics.setAdBlocked(isBlocked);
	RTNewGA4Analytics.setAdBlocked(isBlocked);
  }

  setCustomMetric(idx, value) {
	RTGoogleAnalytics.setCustomMetric(idx, value);
	RTNewGA4Analytics.setCustomMetric(idx, value);
  }
}

const instance = new RTGoogleAnalyticsManager();
export default instance;
