export function ready(fn) {
  if (document.readyState != 'loading'){
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

export const hasSuffix = (field_str, suffix_or_suffixes) => {
  if (!suffix_or_suffixes || !field_str) return false;
  const suffixes = Array.isArray(suffix_or_suffixes) ? suffix_or_suffixes : [suffix_or_suffixes];
  return suffixes.some(suffix => field_str.endsWith(suffix));
}

export const hasLausdSuffix = (field) => {
  const SUFFIXES = ['mymail.lausd.net', 'lausd.net']
  return hasSuffix(field, SUFFIXES);
}

export const hasBlockedSuffix = (field) => {
  const SUFFIXES = ['advtech.co.za']
  return hasSuffix(field, SUFFIXES);
}
