import {isCoppa} from 'common/coppa';
import {openx_config} from './openx_config';
import _ from 'lodash'

export function generateBidders(adUnit, sizes) {
  const coppa = isCoppa();
  const commonConfig = _.result(openx_config, 'all', {});
  const config = _.result(openx_config, `${adUnit.name}`, null);
  if (_.isEmpty(config)) return {};
  if (_.isNumber(adUnit.floor) && adUnit.floor !== 0) config['customFloor'] = adUnit.floor;
  return {
    openx: {
      coppa,
      ...config,
      ...commonConfig
    },
  }
}
