import './polyfills';
import "core-js/stable";
import "regenerator-runtime/runtime";
import log from './common/Logger';
import Ads from 'ads/ads'
import bridge from 'common/bridge';
import Utils from './common/Utils';
import PageRouter from './pages/PageRouter';
import { loadState, saveState } from './pages/new/localstorage';
import { loadCustomerly } from './teachers/customerly';
import abtest from 'common/abtest';
import './main.scss'
import {
  isTeacher,
  getClasses,
  isLoggedIn,
  isSubscriptionActive,
  isImpersonate,
  isStudent,
  socialProvider, isAdmin, shouldSkipRedirect
} from './common/UserService';
import Story from './common/Story';
import { constants } from './config';
import {isCoppa, isCoppaStudent} from 'common/coppa';
import _ from 'lodash';
import RTGoogleAnalyticsManager from 'common/RTGoogleAnalyticsManager';
import { RTGtag } from './common/GTag';
import { RTFacebookPixel } from './common/fbpixel';
import { RTClarity } from './common/clarity';
import {clearSession, setAuthToken} from './common/authtoken';
import { clearSignupStateValues } from './pages/new/signup/signup_helpers';
import userFeatureFlags from "./common/user_feature_flags";
import {disconnect} from "./common/api";
const EXCLUDED_PAGES = [
  '/app/sign-up/role',
  '/auth/login',
  '/app/sign-up/create-account/student',
  '/welcome/demo'
]

window.onerror = function (msg, file, line, col, error) {
  log.error(`${msg} # ${file} # ${line} # ${col} #${error}`)
};

log.info(`rt client ver: ${$$VERSION$$}`);
Ads.bind();

function clearCache() {
  sessionStorage.clear();
}

(async function () {
    window.rt = window.rt || {};
  if (window.rt.appLoaded) {
    log.warn('app already loaded')
    return;
  } else {
    window.rt.appLoaded = true;
  }
  //Timeline.markStart('index');

  try {
    if (Utils.getUrlParams()['clear_cache']) {
      clearCache();
    }
    log.debug(`AB: ${abtest.uid}`)
    Story.init();
    RTGoogleAnalyticsManager.init();

    log.debug("redirect if needed")
    await redirectIfNeeded();
    log.debug("do common logic")
    doCommonLogic();

    // const roleModule = await doRelevantRoleLogic(); //handles student/teacher etc logic

    // handle specific page logic
    log.debug("do relevant page logic")
    await doRelevantPageLogic();

    log.debug("do after page logic")
    doAfterPageLogic();

  } catch (error) {
    // eslint-disable-next-line no-console
    log.error(`${constants.logger.prefix}` + `couldn't start session`, error);
    return;
  }

  //Timeline.markEnd('index');
})();

function bindTopBarAnalyticsEvents() {
  const topNavBar = document.querySelector('#topNavigation-topBar')
  if (topNavBar) {
    const signupBtnEl = topNavBar.querySelector('a[href*="/welcome/register"]')
    if (signupBtnEl) {
      signupBtnEl.addEventListener('click', () => {
        RTGoogleAnalyticsManager.sendEvent('top_menu', 'clicked_sign-up', location.pathname);
      })
    }
    const giveBtnEl = topNavBar.querySelector('a[href*="/support/give"]')
    if (giveBtnEl) {
      giveBtnEl.addEventListener('click', () => {
        RTGoogleAnalyticsManager.sendEvent('top_menu', 'clicked_support-us', location.pathname);
      })
    }
    const loginBtnEl = topNavBar.querySelector('a[href*="/auth/login"]')
    if (loginBtnEl) {
      loginBtnEl.addEventListener('click', () => {
        RTGoogleAnalyticsManager.sendEvent('top_menu', 'clicked_login', location.pathname);
      })
    }
    const linkBtnEls = topNavBar.querySelectorAll('a[href]')
    linkBtnEls.forEach(linkBtnEl => {
      if (!linkBtnEl.href.includes('welcome/register') &&
        !linkBtnEl.href.includes('support/give') &&
        !linkBtnEl.href.includes('auth/login')) {
        linkBtnEl.addEventListener('click', () => {
          RTGoogleAnalyticsManager.sendEvent('top_menu', `clicked_${linkBtnEl.innerText}`, location.pathname);
        });
      }
    });
  }
}

/**
 * Logic that happens each time the app is loaded
 */
function doCommonLogic() {
  Utils.addQueryParamsToLinks();
  bindTopBarAnalyticsEvents();
  storeAuthenticationToken();
  if (!location.pathname.includes('sign-up')) {
    clearSignupStateValues();
  }
}

function storeAuthenticationToken() {
  const token = _.result(bridge, 'session.authorization');
  if (token) {
    setAuthToken(token);
  }
}

/**
 * Registers an init function (entry point) for each one of the application pages.
 * Then the router will take care of running the required page logic
 */
function doRelevantPageLogic() {
  return PageRouter.loadPageModule();
}

function shouldTrack() {
  if (EXCLUDED_PAGES.indexOf(window.location.pathname) > -1) return false;
  if (isLoggedIn() && isCoppa()) return false;
  if (isImpersonate()) return false;
  return true;
}

/**
 * Do this after page is loaded
 */
async function doAfterPageLogic() {
  if (!location.pathname.includes('sales')) {
    if (isTeacher()) {
      loadCustomerly();
    } else {
      if (isSubscriptionActive() || location.pathname.includes('contact')) {
        loadCustomerly();
      }
    }
  }

  //conversion tracking for
  RTGoogleAnalyticsManager.setCoppa(isCoppa());
  updateEmailLabelForCoppa();
  handleLogout();
  if (shouldTrack()) {
    RTGtag.loadScript();
    RTGtag.fireConversion();
    RTFacebookPixel.init();
  }
  await RTClarity.load()
}

function updateEmailLabelForCoppa() {
  if (isCoppa()) {
    const $emailAlertTextEl = document.querySelector('#email-alert-innerContainer #email-alert-text');
    if ($emailAlertTextEl) {
      let $addEmailAnchor = document.querySelector('#email-alert-innerContainer #add-email');
      let emailAlertText = $emailAlertTextEl.textContent;
      let emailAnchorText = $addEmailAnchor.textContent;
      if (!_.isEmpty(getClasses())) {
        emailAlertText = emailAlertText.replace('email', 'school email');
        emailAnchorText = emailAnchorText.replace('email', 'school email');
      } else {
        emailAlertText = emailAlertText.replace("email", "parent email");
        emailAnchorText = emailAnchorText.replace("email", "parent email");
      }
      $emailAlertTextEl.textContent = emailAlertText;
      $addEmailAnchor.textContent = emailAnchorText;
    }
  }
}

function handleLogout() {
  const maybeOldLogoutButton = document.querySelector('#logoutButton');
  if (maybeOldLogoutButton) {
    maybeOldLogoutButton.addEventListener('click',
      function () {
        const state = loadState();
        state.quiz = {
          ...state.quiz,
          isPretestQuiz: false,
          pretestComplete: false,
          isReadyForRealQuiz: false
        };
        saveState(state);
      }, false);
  }
}



async function redirectToWordpress(){
  log.debug('redirectToWordpress')
  if (location.hostname === 'readtheory.org') return;
  if (location.pathname === '/') {
    await disconnect()
    location.replace(`https://readtheory.org`);
  }
}

function shouldRedirect() {
  log.debug('shouldRedirect')
  if (isImpersonate()) {
    log.debug('shouldRedirect: impersonate')
    return false;
  } else {
    log.debug('shouldRedirect: not impersonate')
  }

  if(isAdmin() || isTeacher()) {
    log.debug('shouldRedirect: admin or teacher')
    return false;
  } else {
    log.debug('shouldRedirect: not admin')
  }

  if(shouldSkipRedirect()) {
    log.debug('shouldRedirect: skip redirect')
    return false;
  } else {
    log.debug('shouldRedirect: not skip redirect')
  }

  if(!userFeatureFlags.isActive('redirect')) {
    log.debug('shouldRedirect: redirect is not active')
    return false;
  } else {
    log.debug('shouldRedirect: redirect is active')
  }

  if(!isLoggedIn()){
    log.debug('shouldRedirect: user is not logged in')
    return false;
  } else {
    log.debug('shouldRedirect: user is logged in')
  }

  if(!isStudent()) {
    log.debug('shouldRedirect: user is not student')
    return false;
  } else {
    log.debug('shouldRedirect: user is student')
  }

  if(socialProvider === 'clever') {
    log.debug('shouldRedirect: user is clever')
    return false;
  } else {
    log.debug('shouldRedirect: user is not clever')
  }
  return true;
}


async function doDomainRedirectLogic(token) {
  log.debug('doDomainRedirectLogic')
  if (isCoppaStudent()) {
    if (location.hostname !== constants.coppaDomain) {
      clearSession()
      await disconnect()
      location.replace(`https://${constants.coppaDomain}/app/contact/auth/token?token=${token}`)
    }
  } else if (isStudent()) {
    if (location.hostname !== constants.nonCoppaDomain) {
      clearSession()
      await disconnect()
      location.replace(`https://${constants.nonCoppaDomain}/app/contact/auth/token?token=${token}`);
    }
  }
}

async function redirectIfNeeded() {
  log.debug('redirectIfNeeded')
  await redirectToWordpress()
  if(shouldRedirect()){
    const token = _.result(bridge, 'session.authorization');
    if (token) {
      await doDomainRedirectLogic(token);
    }
  }
}
