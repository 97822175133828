import abtest from 'common/abtest';
import _ from 'lodash';

const default_ix_config = {
  active: true,

  coppa__false: {
    'RT_MyProgress_D970x250_1': {
      siteId: '360055',
    },

    'RT_Quiz_D336x260_1': {
      siteId: '360056',
    },

    'RT_Quiz_D_2': {
      siteId: '360056',
    },

    'RT_Quiz_D_3': {
      siteId: '360056',
    },

    'RT_Quiz_T_1': {
      siteId: '360058',
    },

    'RT_Result_D970x250_1': {
      siteId: '360059'
    },
    'RT_Quiz_D_Left': {
      siteId: '410552'
    }

  },

  coppa__true: {
    'RT_MyProgress_D970x250_1': {
      siteId: '370816',
    },

    'RT_Quiz_D336x260_1': {
      siteId: '370818',
    },

    'RT_Quiz_D_2': {
      siteId: '370818',
    },

    'RT_Quiz_D_3': {
      siteId: '370818',
    },

    'RT_Quiz_T_1': {
      siteId: '370820',
    },

    'RT_Result_D970x250_1': {
      siteId: '370817'
    },

    // 'RT_Quiz_D_Left': {
    //   siteId: '410553'
    // }
  }

};
export const ix_config = _.result(abtest, 'adConfig.config.prebid.index', default_ix_config);
