import GoogleAnalytics from 'common/GoogleAnalytics'
import { RT_GA_ID } from 'config'
import log from './Logger';
import _ from 'lodash';
import Story from './Story';
import { getCommonUser, getUser } from 'common/UserService';
import abtest from 'common/abtest';

class RTGoogleAnalytics extends GoogleAnalytics {

  init(options = null) {
	super.init(options);
    if (window.ga) {
      if (this.isInit) return;
      if (window.ga) {
        this.isInit = true;
        log.debug(`GA::init - tracking page with ${this.trackerId}`);
        _.forEach(this.gaq, gaqCmd => {
          window.ga(gaqCmd[0], gaqCmd[1]);
        });
      } else {
        log.warn(`Analytics::init - no ga object found, skipping`)
      }
    } else {
      log.warn(`Analytics::init - no ga object found, skipping`)
    }
  }

  onAfterInit() {
    super.onAfterInit();
    this.send();

	const user = getUser();
    if (user && user.id) {
		this.setUserId(user.id);
		if (user.student) {
            this.setIsInBelowThirteenClass(''+user.student.isInBelowThirteenClass);
		}
	}

	const userRole = getCommonUser()?.role;
	if (!_.isEmpty(userRole)) {
        this.setUserRole(userRole);
        if (abtest) {
            this.setAbTest(abtest.abTestId);
            this.setAbTestVar(abtest.abTestId+'_'+abtest.id);
        }
	}

	if (location.pathname !== '/reading/welcomeReader' && location.pathname !== '/app/student/quiz') {
		this.trackPageView();
	}
  }

  setIsInBelowThirteenClass(setIsInBelowThirteenClass) {
      this.setCustomDim(6, ''+setIsInBelowThirteenClass);
  }

  /**
   * sets ga to user id
   * @param userId
   */
  setUserId(userId) {
	this.setProperty('userId', userId);
  }

  /**
   * sets ab test var as a custom dimension
   * @param abTestVarId
   */
  setAbTestVar(abTestVarId) {
    this.setCustomDim(5, ''+abTestVarId)
  }

    /**
     * sets ab test as a custom dimension
     * @param testId
     */
    setAbTest(testId) {
        this.setCustomDim(4, ''+testId)
    }

  /**
   * sets ga custom dim to adblocked true
   */
  setAdBlocked(isBlocked) {
    this.setCustomDim(7, ''+isBlocked);
  }

  /**
   * sets ga custom dim to isCoppa true
   * @param isCoppa
   */
  setCoppa(isCoppa) {
    this.setCustomDim(8, ''+isCoppa)
  }

  /**
   * increments the number of completed quizzes in google analytics metrics by 1
   */
  incrementQuizCount() {
    this.setCustomMetric(1, 1)
  }

  /**
   * sets ga custom dim to user role
   */
  setUserRole(role) {
    this.setCustomDim(1, role);
  }

  sendEvent(category, action, label, value, also_report_internally=true) {
    super.sendEvent(category, action, label, value);
    if (also_report_internally) Story.genericEvent(action, category, label, value);
  }
}
const instance = new RTGoogleAnalytics(RT_GA_ID, 'rt');
export default instance;
