import Utils from '../../../common/Utils';
import _ from 'lodash';
import abtest from '../../../common/abtest';
import feature_flags from '../../../common/feature_flags';

const PAID_REFS = ['meta', 'google_pmax_test', 'google_sem_test'];
const PRICING_TABLE_NAME_FOR_PAID_REFS = 'paid';

export function shouldShowCreatingClass() {
  return feature_flags.isActive('signup_create_class');
}

/***
 * This is based on two feature flags, one is for all the users, and the other is specifically for
 * users that got in using paid advertising.
 * @returns returns true iff we should show a pricing table on the signup flow
 */
export function shouldShowPricing() {
  const isActive = feature_flags.isActive('signup_pricing_page');
  if (!isActive) {
      return shouldShowPricingForPaidRef();
  }
  return isActive;
}

// Checks if the user arrived from paid traffic & the feature flag exists
function shouldShowPricingForPaidRef() {
  const isActive = feature_flags.isActive('signup_pricing_page_for_refs');
  return isActive && (isPaidRef() || !_.isEmpty(Utils.getABTestParam()));
}

// Checks if the ref is one of the paid ads ref;
export function isPaidRef() {
  const ref = Utils.getRefFromEnv()
  return _.includes(PAID_REFS, ref);
}

// Returns the correct pricing table the user needs to see on sign up
export function getSignupPricingTableName() {
  let name = 'teacher';
  if (shouldShowPricingForPaidRef()) {
    const refPlan = _.result(abtest, `subscriptionType.plans['${PRICING_TABLE_NAME_FOR_PAID_REFS}'].plans`, null)
    name = !_.isNil(refPlan) ? PRICING_TABLE_NAME_FOR_PAID_REFS : name;
  } else {
    name = feature_flags.get('signup_pricing_page') || name;
  }
  return name;
}

export function clearSignupStateValues() {
  const state = JSON.parse(localStorage.getItem('state'));
  if (state?.teacher) {
	state.teacher.signupStatus = null;
  state.teacher.signupABTest = null;
	state.teacher.schoolInfo = null;
	state.teacher.isSchoolInfoSkipped = false;
  state.teacher.isSchoolPageRedirected = false;
	localStorage.setItem('state', JSON.stringify(state));
  }
}

