import log from './Logger';
import _ from 'lodash';

/**
 * Rides an existing implementation of GA and just adds a new tracker and reports
 */
export default class GoogleAnalytics {
  constructor(trackerId, optionalTrackerName = 'mainTracker', additionalCreateOptions = undefined) {
    this.isInit = false;
    this.trackerId = trackerId;
    this.trackerName = optionalTrackerName;
    this.additionalCreateOptions = additionalCreateOptions;
    this.gaq = [];
  }

  init(options = null) {
    if (this.isInit) return;

    (function (i, s, o, g, r, a, m) {
		i['GoogleAnalyticsObject'] = r;
		i[r] = i[r] || function () {
			(i[r].q = i[r].q || []).push(arguments)
		}, i[r].l = 1 * new Date();
		a = s.createElement(o),
			m = s.getElementsByTagName(o)[0];
		a.async = 1;
		a.src = g;
		m.parentNode.insertBefore(a, m)
	})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    if (window.ga) {
      this.isInit = true;
      window.ga('create', this.trackerId, 'auto', this.trackerName, this.additionalCreateOptions)
      window.ga(`${this.trackerName}.set`, 'anonymizeIp', true);
      this.onAfterInit();
      log.debug(`GA::init - tracking page with ${this.trackerId}`);
      _.forEach(this.gaq, gaqCmd => {
        window.ga(gaqCmd[0], gaqCmd[1]);
      });
    } else {
      log.warn(`Analytics::init - no ga object found, skipping`)
    }
  }

  onAfterInit() {}

  setCustomDim(idx, value) {
    window.ga(`${this.trackerName}.set`, 'dimension'+idx, value);
  }

  setProperty(name, value) {
    window.ga(`${this.trackerName}.set`, name, ''+value);
  }

  setCustomMetric(idx, value) {
    window.ga(`${this.trackerName}.set`, 'metric'+idx, value);
  }

  trackPageView(optionalUrl) {
    const cmdArgs = { hitType: 'pageview' };
    if (optionalUrl) {
      cmdArgs.page = optionalUrl
    }
    this.send(`${this.trackerName}.send`, cmdArgs);
  }

  sendEvent(category, action, label, value) {
    const data = {
      hitType: 'event',
    };
    let str = '';
    if (!_.isEmpty(category)) {
      data.eventCategory = category;
      str = `category: ${category} `
    }
    if (!_.isEmpty(action)) {
      data.eventAction = action;
      str += `action: ${action} `
    }
    if (!_.isEmpty(label)) {
      data.eventLabel = label;
      str += `label: ${label} `
    }
    if (!_.isEmpty(value)) {
      data.eventValue = value;
      str += `value: ${value} `
    }

    log.debug(`sending ga event - ${str}`);
    this.send(`${this.trackerName}.send`, data);
  }

  sendTiming(timingCategory, timingVar, timingLabel, timingValue) {
    if (_.isUndefined(timingValue)) {
      timingValue = Math.round(performance.now());
    }
    let timingObj = {timingCategory, timingVar, timingLabel, timingValue};
    timingObj = _.pickBy(timingObj, _.identity);
    log.debug(`sending ga timing: `, timingObj);
    window.ga(`${this.trackerName}.send`, 'timing', timingObj);
  }

  send(commandName, commandArgs) {
    if (!this.isInit) this.init();
    if (window.ga) {
      window.ga(commandName, commandArgs);
    } else {
      this.gaq.push([commandName, commandArgs]);
    }
  }
}

