import {conversant_config} from './conversant_config';
import _ from 'lodash';
import {isCoppa} from '../../../common/coppa';

export function generateBidders(adUnit, sizes) {
  const coppa = isCoppa();
  const commonConfig = _.result(conversant_config, 'all', {});
  const config = _.result(conversant_config, `[coppa__${coppa}][${adUnit.name}]`, null);
  if (_.isEmpty(config)) return {};
  if (_.isNumber(adUnit.floor) && adUnit.floor !== 0 && !_.isNaN(adUnit.floor)) config['bidfloor'] = adUnit.floor;
  return {
    conversant: {
      ...config,
      ...commonConfig
    },
  }
}
