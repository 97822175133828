import _ from 'lodash';
import abtest from './abtest';
import log from 'common/Logger';
import DEBUG from './debug';

let feature_flags = _.result(abtest, 'config', '{"feature_flags": {}}');
try {
  feature_flags = JSON.parse(feature_flags)['feature_flags']
} finally {
  if (feature_flags) {
    if (DEBUG) {
      console.group('Feature Flags');
      console.table(feature_flags);
      console.groupEnd();
    }
  } else {
    feature_flags = {}
    log.warn('could not pull feature flags from ab, returning none')
  }
  feature_flags.isActive = (feature_flag_name) => {
    return !!feature_flags[feature_flag_name]
  }
  feature_flags.get = (feature_flag_name) => {
    return feature_flags[feature_flag_name];
  }
}
export default feature_flags

