import {smartadserver_config} from './smartadserver_config';
import _ from 'lodash';

export function generateBidders(adUnit, sizes) {
  const commonConfig = _.result(smartadserver_config, 'all', {});
  const config = _.result(smartadserver_config, `${adUnit.name}`, null);
  if (_.isEmpty(config)) return {};
  if (_.isNumber(adUnit.floor) && adUnit.floor !== 0 && !_.isNaN(adUnit.floor)) config['floor'] = adUnit.floor;
  return {
    smartadserver: {
      ...config,
      ...commonConfig
    },
  }
}
