import _ from 'lodash';
import log from 'common/Logger';
import {PAGE_ROUTES} from './page_routes';
import Story from '../common/Story';
import {isTeacher, getUser, getStudent, getClasses} from '../common/UserService';
// import {loadSendinblue, sendEvent} from '../teachers/sendinblue.js';
/**
 * This class handles loading the correct module for each page dynamically
 */
class PageRouter {

  curr_page

  constructor() {}

  async loadMainPageModule() {
	try {
		let pageModule = await import(/* webpackChunkName: "[request]" */ 'pages/MainPage.js');
		pageModule = pageModule.default;
		pageModule.load();
	} catch(e) {
		log.error('failed to load main page module', e);
	}
  }

  async loadPageModule() {
    const hostPath = window.location.pathname;
    const routeKey = _.head(_.filter(_.keys(PAGE_ROUTES), (route) => {
      return _.includes(_.toLower(hostPath), _.toLower(route));
	}));

	if (_.isEmpty(routeKey)) {
		await this.loadMainPageModule();
		return null;
	}

	const jsFile = PAGE_ROUTES[routeKey];
	try {
	  let pageModule = await import(/* webpackChunkName: "[request]" */ `pages/${jsFile}`);
	  //FIXME: this is a bug in webpack, should fix the 'default' import if it happens
	  this.curr_page = pageModule = pageModule.default;
	  if (Story.shouldFireLoginEvent() & this.isAuth()) {
		Story.addStep('login', {param1: isTeacher() ? 'teacher' : 'student'});
	  }
	  const isUpdateAgeNeeded = Story.shouldFireLoginEvent() && this.isAuth() && !_.isEmpty(getStudent()) && _.isEmpty(getStudent().dob) && _.isEmpty(getClasses());
	  if (isUpdateAgeNeeded) {
		localStorage.setItem('rt-signed-in', 1);
	  }
	  pageModule.load();
	  return pageModule;
	} catch (e) {
	  log.error('failed to load page module', e);
	  return null;
	}
  }

  isAuth() {
    return _.result(this.curr_page, 'auth_required', false);
  }

  getPage() {
    return this.curr_page
  }

}
export default new PageRouter();
