import abtest from 'common/abtest';
import _ from 'lodash';
const default_rubicon_config = {
    active: true,

    'all': {
        accountId: '17174',
        siteId: '163770'
    },

    'RT_Quiz_D336x260_1': {
        zoneId: '1485168'
    },

    'RT_Quiz_D_2': {
        zoneId: '1485168'
    },

    'RT_Quiz_D_3': {
        zoneId: '1485168'
    },

    'RT_Quiz_T_1': {
        adSlot: '1485184'
    },

    'RT_Result_D970x250_1': {
        zoneId: '1485172'
    },

    'RT_Quiz_D_Left': {
        zoneId: '1485170'
    },

    'RT_MyProgress_Top_728': {
        zoneId: '1485174'
    },

    'RT_MyProgress_Left': {
        adSlot: '1485178'
    },

    'RT_MyProgress_D970x250_1': {
        zoneId: '1485180'
    }

};
export const rubicon_config = _.result(abtest, 'adConfig.config.prebid.rubicon', default_rubicon_config);
