import _ from 'lodash';
import { RT_GA4_TAG_ID } from 'config';
import log from './Logger';
import { getUser, getCommonUser, isTeacher, isSubscriptionActive } from './UserService'
import abtest from './abtest';
import { GOOGLE_ANALYTICS_METRIC_MAP } from 'config';
import { getAuthToken } from './authtoken';
import { getUserSchoolAccount } from './api';

class RTNewGA4Analytics {

  constructor(tagId) {
    this.tagId = tagId;
	this.isInit = false;
	this.customDimension = {};
	this.userScopeDimension = {};
	this.customMetric = {};
	this.schoolAccountId = null;
  }

  init(optionalTagIds = []) {
	if (!this.isInit && this.tagId) {
	  (function (i, s, o, g, a, m) {
		a = s.createElement(o),
		m = s.getElementsByTagName(o)[0];
		a.async = 1;
		a.src = g;
		m.parentNode.insertBefore(a, m)
	  })(window, document, 'script', `https://www.googletagmanager.com/gtag/js?id=${this.tagId}`);

	  window.dataLayer = window.dataLayer || [];
	  function gtag(){dataLayer.push(arguments);}
	  gtag('js', new Date());
	  const user = getUser();
	  gtag('config', this.tagId,
	  	{
		  'user_id': user?.id,
		  send_page_view: false
		});

	  optionalTagIds.forEach(tagId => gtag('config', tagId));
	}

  	this.onAfterInit();
  }

  async onAfterInit() {
    const user = getUser();
    if (user && user.student) {
      this.setIsInBelowThirteenClass(user.student.isInBelowThirteenClass);
    }

    const userRole = getCommonUser()?.role;
    if (!_.isEmpty(userRole)) {
      this.setUserRole(userRole);
      if (abtest) {
        this.setAbTest(abtest.abTestId);
        this.setAbTestVar(abtest.abTestId + "_" + abtest.id);
      }
    }

    if (isTeacher() && getAuthToken() && !this.schoolAccountId) {
      try {
        const result = await getUserSchoolAccount(getUser().id);
        this.schoolAccountId = result?.data?.id;
      } catch {
        this.schoolAccountId = null;
      }
    }

    this.trackUserProperties();
    this.trackPageView(window.location.href);
  }

  sendEvent(name, params) {
    let str = "";
    _.keys(params).forEach((key) => {
      str += `${key}: ${params[key]}`;
    });

    if (isTeacher()) {
      str += `premium_school: ${!!this.schoolAccountId}`;
      params.premium_school = !!this.schoolAccountId;
    }

    log.debug(`sending ga4 event - ${str}`);

    gtag("event", name, params);
  }

  setPremiumSchoolParams(params) {
    if (isTeacher()) {
      params.premium_school = !!this.schoolAccountId;
    }
  }

  trackUserProperties() {
    if (getUser()) {
      const params = {
        ...this.userScopeDimension,
        is_pro: isSubscriptionActive(),
      };
      this.setPremiumSchoolParams(params);
      gtag("set", "user_properties", params);
    }
  }

  trackPageView(pagePath) {
    const params = {
      page_location: pagePath,
      ...this.customDimension,
      ...this.customMetric,
    };
    this.setPremiumSchoolParams(params);
    gtag("event", "page_view", params);
  }

  setCustomDim(key, value, scope = 'event') {
	if (scope === 'event') {
	  this.customDimension[key] = value;
	} else {
	  this.userScopeDimension[key] = value;
	}
  }

  setIsInBelowThirteenClass(isInBelowThirteenClass) {
	this.setCustomDim('is_below_thirteen_class', isInBelowThirteenClass);
  }

  setUserRole(role) {
	this.setCustomDim('user_role', role, 'user');
  }

  setAbTest(testId) {
	this.setCustomDim('ab_test_id', testId);
  }

  setAbTestVar(abTestVarId) {
	this.setCustomDim('ab_test_var_id', abTestVarId);
  }

  setCoppa(isCoppa) {
	this.setCustomDim('is_coppa', isCoppa);
  }

  setAdBlocked(isBlocked) {
	this.setCustomDim('is_ad_blocked', isBlocked);
  }

  setCustomMetric(idx, value) {
	const metricKey = GOOGLE_ANALYTICS_METRIC_MAP[`metric${idx}`];
	if (metricKey) {
	  this.customMetric[metricKey] = value;
	}
  }
}

const instance = new RTNewGA4Analytics(RT_GA4_TAG_ID);
export default instance;
