import _ from 'lodash'
import {isCoppa} from '../../common/coppa';
import {generateBidders as ixGenerateBidders}  from './bidders/ix_generator';
import {generateBidders as sovrnGenerateBidders} from './bidders/sovrn_generator';
import {generateBidders as sharethroughGenerateBidders} from './bidders/sharethrough_generator';
import {generateBidders as rubiconGenerateBidders} from './bidders/rubicon_generator';

import {generateBidders as appnexusGenerateBidders} from './bidders/appnexus_generator';
import {generateBidders as openxGenerateBidders} from './bidders/openx_generator';
import {generateBidders as smartGenerateBidders} from './bidders/smartadserver_generator';
import {generateBidders as conversantGenerateBidders} from './bidders/conversant_generator';
// import {generateBidders as pubmaticGenerateBidders} from './bidders/pubmatic_generator';


// import {generateBidders as emxGenerateBidders} from './bidders/emx_generator';

const bidderGenFuncs = [];
bidderGenFuncs.push(ixGenerateBidders);
bidderGenFuncs.push(sharethroughGenerateBidders);
bidderGenFuncs.push(rubiconGenerateBidders);
bidderGenFuncs.push(appnexusGenerateBidders);
bidderGenFuncs.push(openxGenerateBidders);
// bidderGenFuncs.push(pubmaticGenerateBidders);
if (!isCoppa()) {
  bidderGenFuncs.push(sovrnGenerateBidders);
  bidderGenFuncs.push(smartGenerateBidders);
  bidderGenFuncs.push(conversantGenerateBidders);
}
// bidderGenFuncs.push(pubmaticGenerateBidders);

// bidderGenFuncs.push(emxGenerateBidders);

export function generateAllBidders(adUnit, sizes) {
  sizes = _.filter(sizes, size => size !== 'fluid')
  return _.reduce(bidderGenFuncs, (bidders, currGenFunc) => {
    bidders = _.assign(bidders, currGenFunc(adUnit, sizes))
    return bidders;
  }, {})
}
