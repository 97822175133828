import {getUser, isStudent} from './UserService';
import _ from 'lodash';

export function isCoppa() {
    const user = getUser();
    return _.result(user, 'isCoppa', true);
}

export function isCoppaStudent() {
  return isStudent() && isCoppa();
}
