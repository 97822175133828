/**
 * This maps all routes into page modules, e.g. when /welcome/register is visited the module signup/register.js
 * is dynamically downloaded and executed.
 * ---- Important! ---
 * The order of the paths is important because the router is selecting the first match that starts with the prefix
 * e.g. if '/' will be the first path in this file then HomePage.js will always be called instead of the correct path
 *
 */
export const PAGE_ROUTES = {
  '/app/contact/auth/token': 'TokenLoginPage.js',
  '/app/': 'new/vue_entry_point.js',
  '/welcome/register': 'signup/register.js', //signup select role
  '/user/teacherDetails': 'new/signup/serverTeachingDetails.js', //after teacher sign-up more info screen
  '/contact': 'ContactPage.js',

  '/google/success': 'TokenLoginPage.js',
  '/auth/login': 'LoginPage.js',
  '/google/loginFail': 'LoginPage.js',
  '/welcome/demo': 'WelcomePretestPage.js',
  //auth required
  '/welcome/aboutUs': 'AboutUsPage.js',
  '/reading/welcomeReader': 'QuizPage.js',
  '/reading/presentPretest': 'PreTestQuizPage.js',
  '/reading/quiz/': 'QuizPage.js',
  '/reading/myTeachers': 'MyTeachersPage.js',
  '/dashboard/index': 'MyProgressPage.js',
  '/user/manage': 'UserDetailPage.js',
  '/user/updateEmail': 'UserDetailPage.js',
  '/user/updatePassword': 'UserDetailPage.js',
  //teacher only
  '/support/give': 'SupportUsPage.js',
  '/teaching/showCreateClass': 'teaching/CreateClassPage.js',
  '/teaching/createClass': 'teaching/CreateClassPage.js',
  '/teaching/myClasses': 'teaching/MyClassesPage.js',
  '/teaching/myStudents': 'teaching/MyStudentsPage.js',
  '/teaching/studentReport': 'teaching/StudentReportPage.js',
  '/reading/reviewLastQuizResponse': 'QuizCompletePage.js',
  '/teaching/listWorksheets': 'teaching/ListWorksheetsPage.js',
  //admin only
  '/admin/manageCoppaSuffixes': 'ListCoppaSuffixesPage.js',
  '/': 'Home.js', //IMPORTANT: this path should always be the last in this file
};
